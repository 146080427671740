import {
  Card,
  Typography,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect, useState, useRef } from "react";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import PhoneIcon from "@mui/icons-material/Phone";
import axiosInstance from "../api/axios";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, useNavigate } from "react-router-dom";
import UnRegisterdLayout from "../components/layouts/UnRegisterdLayout";
import Sucss from "../assets/images/sucss.svg";
import { useSearchParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import {
  CountrySelector,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import "react-international-phone/style.css";
import axios from "axios";
export default function CreateAccount() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const user = searchParams.get("userType");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

  const [phone, setPhone] = useState("+44");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [flag, setFlag] = useState("gb");
  const [userType, setUserType] = useState(user);

  const [successMsg, setSuccessMsg] = useState(false);
  const [userCountry, setUserCountry] = useState("");
  const [country, setCountry] = useState("");

  const countryCode = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ["ca", "lk", "gb"].includes(iso2);
  });
  const countries = {
   
    "United Kingdom": { currencySymbol: "GBP" },
    "Sri Lanka": { currencySymbol: "LKR" },
    "Canada": { currencySymbol: "CAD" },
  };
  const handleCaptchaChange = (value) => {
    // value will be null if reCAPTCHA is not verified
    setIsCaptchaVerified(value !== null);
  };

  useEffect(() => {
    axios
      .get("https://geolocation-db.com/json/")
      .then((response) => {
        const data = response.data;
          setUserCountry(data?.country_name);
          setCountry(data?.country_name);
          const flag = 
   
         data?.country_name === "United Kingdom" ? "gb" :
         data?.country_name === "Sri Lanka" ? "lk" :
         data?.country_name === "Canada" ? "ca" :
          "";
          const phone = 
     
       data?.country_name === "United Kingdom" ? "+44" : 
       data?.country_name === "Sri Lanka" ? "+94" : 
       data?.country_name === "Canada" ? "+1" : ""; 
    
      setPhone(phone);
        setFlag(flag);
      })
      .catch((error) => {
        console.error("Error fetching IP:", error);
      });
  }, [userCountry]);
  const handleCreateUser = async () => {
   
    if (!name) {
      setNameError(true);
      return;
    }
    if (!email) {
      setEmailError(true);
      return;
    }
    if(!country){
      toast.error("Please select your country");
      return;
    }
    const phoneRegex = /^(\+\d{1,3})?\d{10}$/;

    if (!phoneRegex.test(phone)) {
      toast.error("Please enter a valid phone number.");
      return;
    }
    
    if (!password || !confirmPassword) {
      toast.error("Please enter Password");
      return;
    }
    if (passwordError ) {
      toast.error("Password must be 8-12 characters long and include at least one capital letter, one number, and one special character");
      return;
    }
    
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
    if (!userType) {
      toast.error("Please select a user type");
      return;
    }
    if (!isCaptchaVerified) {
      toast.error("Please Verify Captcha");
      return;
    }
   
    const toastId = toast.loading("Please wait...");
    try {
      const userData = {
        user_name: name,
        email: email,
        phone_number: phone,
        password: password,
        user_type:userType,
        country:country
      };

      const response = await axiosInstance.post(
        `/api/auth/createUser`,
        userData
      );

      if (response.data) {

if (response.status=="200"){
  toast.update(toastId, {
    render: "Account created successfully, check your email and verify",
    type: "success",
    isLoading: false,
    autoClose: 3000,
  });
  setSuccessMsg(true);
  setName("");
  setEmail("");
  setPassword("");
  setPhone("");
  setConfirmPassword("");
  setTimeout(() => {
    navigate("/login");
  }, 12000);
}
else{
  toast.update(toastId, {
    render: response?.data?.message,
    type: "error",
    isLoading: false,
    autoClose: 3000,
  });
}
      
      }
    } catch (error) {
      setSuccessMsg(false);
      console.log("error", error.response?.data?.message);
      console.error(error);
      toast.update(toastId, {
        render: error.response?.data,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  const handleUserTypeChange = (event) => {
    setUserType(event.target.value);
  };
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
    const flag = 
   
      event.target.value === "United Kingdom" ? "gb" :
      event.target.value === "Sri Lanka" ? "lk" :
      event.target.value === "Canada" ? "ca" :
      "";
      const phone = 
 
    event.target.value === "United Kingdom" ? "+44" : 
    event.target.value === "Sri Lanka" ? "+94" : 
    event.target.value === "Canada" ? "+1" : ""; 

  setPhone(phone);
    setFlag(flag);
   
  };
  
  return (
    <UnRegisterdLayout>
      <Container maxWidth="xl" sx={{ mt: "4vh", mb: "4vh", padding: "2px" }}>
        {successMsg ? (
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "18px",
              textAlign: "center",
            }}
          >
            <Typography variant="body1">
              Account created successfully<br></br>
              Please check your email for verification.
            </Typography>
            <img
              src={Sucss}
              alt="Sucss"
              style={{ width: "100%", maxWidth: "500px" }}
            />
          </Grid>
        ) : (
          <Card
            sx={{
              backgroundColor: "#ECEBEB",
              maxWidth: "538px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              textAlign: "center",
            }}
          >
            <Typography variant="h1" sx={{ mt: "5%", fontWeight: 400 }}>
              Create Your Account
            </Typography>
            {/* <Typography variant="body3">
              Reference site about Lorem Ipsum, giving information
            </Typography> */}
            <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
              <TextField
                sx={{ width: "100%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircleIcon sx={{ color: "black" }} />
                    </InputAdornment>
                  ),
                  sx: {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "15px",
                    borderColor: "#EE548A",
                    margin: "0% 2%",
                    fontSize: "16px",
                  },
                }}
                placeholder="User Name"
                variant="outlined"
                name="name"
                id="name"
                value={name}
                onChange={(e) => {
                  const enteredName = e.target.value;
                  setName(enteredName);
                  setNameError(!/^[a-zA-Z].*$/.test(enteredName));
                }}
                error={nameError}
                helperText={nameError && "Name must start with a letter"}
              />
            </div>
            <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
              <TextField
                sx={{ width: "100%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon sx={{ color: "black" }} />
                    </InputAdornment>
                  ),
                  sx: {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "15px",
                    borderColor: "#EE548A",
                    fontSize: "16px",
                    margin: "0% 2%",
                  },
                }}
                placeholder="Email Address"
                variant="outlined"
                value={email}
                name="email"
                id="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(!/\S+@\S+\.\S+/.test(e.target.value));
                }}
                error={emailError}
                helperText={emailError && "Please enter a valid email address"}
              />
            </div>
            <Grid
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                width: "100%",
                maxWidth: "440px",
                margin: "0% 10%",
              }}
            >   <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
              <FormControl sx={{ width: "100%" }}>
                <Select
                  value={userType?userType:"select"}
                  onChange={handleUserTypeChange}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "15px",
                    borderColor: "#EE548A",
                    fontSize: "16px",
                    margin: "0% 2%",
                    color:userType?"":'#BCBCBC !important',
                    textAlign: "left",
                  }}
                >
                     <MenuItem value="select" disabled>
                      Please Select User Type
                    </MenuItem>
                  <MenuItem value="BUYER_USER">Cake Buyer</MenuItem>
                  <MenuItem value="SELLER_USER">Cake Seller</MenuItem>
                </Select>
              </FormControl>
              </div>
              <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
              <FormControl sx={{ width: "100%", }}>
                <Select
                  value={country?country:"select"}
                  onChange={handleCountryChange}
                  sx={{
                    backgroundColor: "#FFFFFF",
                    borderRadius: "15px",
                    borderColor: "#EE548A",
                    fontSize: "16px",
                    margin: "0% 2%",
                    color:country?"":'#BCBCBC !important',
                    textAlign: "left",
                  }}
                >
                     <MenuItem value="select" disabled>
                      Please Select Country
                    </MenuItem>
                    {Object.keys(countries).map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            </Grid>  
            <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
              <TextField
                sx={{ width: "100%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CountrySelector
                        selectedCountry={flag}
                        countries={countryCode}
                        onSelect={({ iso2, dialCode }) => {
                          setPhone(`+${dialCode}`);
                          setFlag(iso2);
                        }}
                      />
                    </InputAdornment>
                  ),
                  sx: {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "15px",
                    borderColor: "#EE548A",
                    fontSize: "16px",
                    margin: "0% 2%",
                  },
                }}
                placeholder="Phone Number"
                variant="outlined"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                  setPhoneError(!/^\+[\d\s-]+$/.test(e.target.value));
                }}
                error={phoneError}
                helperText={phoneError && "Please enter a valid phone number"}
              />
            </div>
            <Grid
              sx={{
                display: "flex",
                flexDirection:"column",
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                width: "100%",
                maxWidth: "440px",
                margin: "0% 2%",
              }}
            >
              <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
                <TextField
                  sx={{ width: "100%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon sx={{ color: "black" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? (
                            <VisibilityIcon sx={{ color: "black" }} />
                          ) : (
                            <VisibilityOffIcon sx={{ color: "black" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "15px",
                      borderColor: "#EE548A",
                      fontSize: "16px",
                      height: "57px",
                      margin: "0% 2%",
                    },
                  }}
                  placeholder="Password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    const enteredPassword = e.target.value;
                    setPassword(enteredPassword);
                    setPasswordError(
                      
                      !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,12}$/.test(
                        enteredPassword
                      )
                    );
                  }}
                  error={passwordError}
                  helperText={passwordError && "Invalid password"}
                />
              </div>
              <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
                <TextField
                  sx={{ width: "100%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon sx={{ color: "black" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          aria-label="toggle confirm password visibility"
                        >
                          {showConfirmPassword ? (
                            <VisibilityIcon sx={{ color: "black" }} />
                          ) : (
                            <VisibilityOffIcon sx={{ color: "black" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "15px",
                      borderColor: "#EE548A",
                      fontSize: "16px",
                      margin: "0% 2%",
                    },
                  }}
                  placeholder="Confirm Password"
                  variant="outlined"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => {
                    const enteredConfirmPassword = e.target.value;
                    setConfirmPassword(enteredConfirmPassword);
                    setConfirmPasswordError(
                      enteredConfirmPassword !== password
                    );
                  }}
                  error={confirmPasswordError}
                  helperText={confirmPasswordError && "Passwords do not match"}
                />
              </div>
            </Grid>

            <div style={{ maxWidth: "420px" }}>
              <input
                type="radio"
                id="Accept"
                name="Accept"
                value="Accept"
                style={{ transform: "scale(1.5)" }}
              />
              &nbsp;&nbsp;
            <Typography variant="body3">
                I Accept the   <Link to="/terms-conditions" style={{color:'blue'}}> Terms of Service</Link> and  <Link style={{color:'blue'}} to="/privacy-policy"> Privacy Policy</Link> for this site.
              </Typography>
            </div>
            <ReCAPTCHA
        sitekey="6LfV7YUoAAAAAKyasTozguiO__mkcJ8xPDcbaawX"
        onChange={handleCaptchaChange}
      />
            <Button
              variant="contained"
              sx={{ maxWidth: "250px" }}
              onClick={handleCreateUser}
            >
              Create
            </Button>

            <Typography variant="body3" sx={{ mb: "5%" }}>
              If you already have an account, please <br />
              <b>
                <a href="/login">Sign in</a>{" "}
              </b>
            </Typography>
          </Card>
        )}
      </Container>
    </UnRegisterdLayout>
  );
}
