import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#EE548A ",
    },
    secondary: {
      main: "#FCDDE8",
    },
  
  },
  typography: {
    fontFamily: "Poppins",
    h1: {
      fontWeight: 700,
      fontSize: "36px",
      lineHeight: "54px",
      color: "#000000",
    },
    banner: {
      fontWeight: 400,
      fontFamily: "Poppins",
      fontSize: "36px",
      lineHeight: "54px",
      color: "#000000",
    },

    h2: {
      fontWeight: 400,
      fontSize: "30px",
      lineHeight: "45px",
    },
    h3: {
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: "27px",
    },
    h4: {
      fontWeight: 500,
      fontSize: "1.5rem",
      lineHeight: 1.2,
    },
    h5: {
     
      fontStyle: "normal",
      fontWeight: "700",
      fontSize: "48px",
      lineHeight: "61px",
    },
    h6: {
      fontWeight: 400,
      fontSize: "14px",
   
    },
    subtitle1: {
      fontSize: "1rem",
      lineHeight: 1.2,
    },
    body1: {
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: "30px ",
    },
    body2:{
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '33px',
      
    },
    body3:{
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '21px',
      fontFamily: "Poppins",
    },
  
  },
  components: {
  
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.15) !important",
          borderRadius: "24px !important",
          background: " #F9FAFB !important",
          width: "100% !important",
         // maxWidth: "692px !important",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: "#FFFFFF",
          boxShadow: "1px 4px 8px 6px rgba(0, 0, 0, 0.25)",
          borderRadius: "45px",
          margin: "0 auto",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: "54px",
          width: "100% ",
          fontWeight: "400 ",
          fontSize: "20px ",
          height: "45px ",
          "@media (max-width:600px)": {
            fontSize: "14px !important",
           
          },
          
        },
        contained: {
          background: "#EE548A ",
           color:'white',
          "&:hover": {
            background: "#e02466",
          },
        },
        outlined: {
          border: "1px solid #EE548A ",
          color:'#EE548A',
          "&:hover": {
            border: "1px solid #EE548A ",
            color: "#EE548A  ",
          },
        },
      },
    },
  },
});

export default theme;
