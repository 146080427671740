import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import AppRouter from './components/router/Router';
import theme from './components/theme/Theme';
import './style.css';
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthProvider from "../src/components/context/AuthContext";
import CookieConsent from 'react-cookie-consent';

function App() {
  return (
    <ThemeProvider theme={theme}> 
     <AuthProvider>
       <ToastContainer
            position="bottom-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
   <CookieConsent
        position="bottom" // Position the banner at the bottom
        buttonText="Accept Cookies"
        flipButtons
        enableDeclineButton
        declineButtonText="Decline"
        linkText="Learn More"
        linkUrl="https://your-website.com/cookie-policy"
        style={{
          background: '#f5f5f5', // Subtle background color
          color: '#333',
          padding: '12px',
          margin:'0 auto',
          border: '1px solid #ccc',
          borderRadius: '5px',
        }}
      >This website uses cookies to enhance the user experience.
      </CookieConsent>
      <AppRouter />
   
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;