import axios from "axios";

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});


const JsonHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

axiosInstance.interceptors.request.use(
  (config) => {
    const data = localStorage.getItem('user');
  
    const user = JSON.parse(data);
    const accessToken = user?.accessToken;
   // const refreshToken = user?.refreshToken;
    
    const excludedEndpoints = ['/login', '/signup', '/contact', '/resetpassword', '/verifyAccount'];
    const shouldExclude = excludedEndpoints.some(endpoint => config.url.includes(endpoint));
    
    if (accessToken && !shouldExclude) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.postJson = async (url, body) => {
  return axiosInstance.post(url, body, {
    headers: JsonHeaders,
  });
};

axiosInstance.getJson = async (url) => {
  return axiosInstance.get(url, {
    headers: JsonHeaders,
  });
};

export default axiosInstance;
