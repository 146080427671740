import { Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/layouts/AdminLayout";
import Active from "../../assets/icons/active.svg";
import Disable from "../../assets/icons/admin4.svg";
import Time from "../../assets/icons/admin3.svg";
import Cake from "../../assets/icons/admin2.svg";
import Tick from "../../assets/icons/admin1.svg";
import axiosInstance from "../../api/axios";

export default function Home() {
  const [data, setData] = useState([]);
  const [user, setUser] = useState('');
  useEffect(() => {
    getCakes();
    getUsers();
  }, []);
  
  const getUsers = async () => {
    try {
      axiosInstance
        .get("/api/user/getAll")
        .then((response) => {
          const users = response?.data?.data?.length;
          setUser(users || []);
          
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
    }
  };
  const getCakes = async () => {
    try {
      axiosInstance
        .get("/api/stats/getAll")
        .then((response) => {
          const data = response?.data?.data;
          setData(data || []);
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
    }
  };
  return (
    <AdminLayout>
      <Grid
        xs={10}
        sx={{
          display: "flex",
          flexWrap: {xs:'nowrap',sm:"wrap"} ,
          justifyContent: {xs:'center',sm:"space-evenly"} ,
          alignItems: "start",
          flexDirection:{xs:'column',sm:'row'},
          alignItems:'center',
          gap: "16px",
     
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: "8px",
            background: " #FFF",
            padding: "18px",
            boxShadow: " 1px 1px 4px 2px rgba(0, 0, 0, 0.25)",
          }}
        >
          <img loading="lazy" src={Active} alt="sa" />
          &nbsp;
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            <Typography> Active Users</Typography>
            <Typography> {data?.active_users}</Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: "8px",
            background: " #FFF",
            padding: "18px",
            boxShadow: " 1px 1px 4px 2px rgba(0, 0, 0, 0.25)",
          }}
        >
          <img loading="lazy" src={Disable} alt="sa" />
          &nbsp;
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            <Typography> Blocked Users</Typography>
            <Typography> {data?.blocked_users}</Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: "8px",
            background: " #FFF",
            padding: "18px",
            boxShadow: " 1px 1px 4px 2px rgba(0, 0, 0, 0.25)",
          }}
        >
          <img loading="lazy" src={Cake} alt="sa" />
          &nbsp;{" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            <Typography> Total Cakes</Typography>
            <Typography> {data?.total_cakes}</Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: "8px",
            background: " #FFF",
            padding: "18px",
            boxShadow: " 1px 1px 4px 2px rgba(0, 0, 0, 0.25)",
          }}
        >
          <img loading="lazy" src={Time} alt="sa" />
          &nbsp;
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            <Typography> Pending Cakes</Typography>
            <Typography> {data?.pending_cakes}</Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: "8px",
            background: " #FFF",
            padding: "18px",
            boxShadow: " 1px 1px 4px 2px rgba(0, 0, 0, 0.25)",
          }}
        >
          <img loading="lazy" src={Tick} alt="sa" />
          &nbsp;
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            <Typography> Approved Cakes</Typography>
            <Typography> {data?.approved_cakes}</Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
            borderRadius: "8px",
            background: " #FFF",
            padding: "18px",
            boxShadow: " 1px 1px 4px 2px rgba(0, 0, 0, 0.25)",
          }}
        >
          <img loading="lazy" src={Active} alt="sa" />
          &nbsp;
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              gap: "6px",
            }}
          >
            <Typography> Totall Users</Typography>
            <Typography> {user}</Typography>
          </div>
        </div>{" "}
      </Grid>
    </AdminLayout>
  );
}
