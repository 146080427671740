import React from 'react';
import { Typography, Container
  // , List, ListItem, ListItemText
 } from '@mui/material';
import UnRegisterdLayout from '../components/layouts/UnRegisterdLayout';

export default function PrivacyPolicy() {
  return (
    <UnRegisterdLayout> 
    <Container maxWidth="md" >
      <Typography variant="h4" mb={4} gutterBottom>
        Privacy Policy
      </Typography>

      <Typography variant="h3" mb={2} gutterBottom>
        Information We Collect
      </Typography>
      <Typography variant="body1" mb={2}>
        We collect information that you provide directly to us. This may include your name, email address, and any other information you choose to provide.
      </Typography>

      <Typography variant="h3" mb={2} gutterBottom>
        How We Use Your Information
      </Typography>
      <Typography variant="body1" mb={2}>
        We use the information we collect to provide, maintain, and improve our services. This includes sending you updates and information related to our services.
      </Typography>

      <Typography variant="h3" mb={2} gutterBottom>
        Your Choices
      </Typography>
      <Typography variant="body1" mb={2}>
        You can control your personal information and privacy preferences by contacting us. You may choose to opt out of receiving communications from us at any time.
      </Typography>

      {/* <Typography variant="h3" gutterBottom>
        Bullet Points
      </Typography>
      <List>
        <ListItem>
          <ListItemText primary="We do not share your information with third parties." />
        </ListItem>
        <ListItem>
          <ListItemText primary="We take security measures to protect your data." />
        </ListItem>
        <ListItem>
          <ListItemText primary="You can request to access, correct, or delete your personal information." />
        </ListItem>
      </List> */}
    </Container></UnRegisterdLayout>
  );
}
