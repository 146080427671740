import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import {
  // Avatar,
  Dialog,
  Button,
  Grid,
  // Rating,
  Typography,
} from "@mui/material";
import Hero from "../assets/images/hero.webp";
import SearchBar from "../components/core/SearchBar";
import ProductCard from "../components/cards/ProductCard";
import Spinner from "../assets/images/Spinner.gif";
import Banner from "../components/cards/Banner";
// import BannerImg from "../assets/images/banner.png";
import BannerTest from "../assets/images/test.jpg";
import Rest from "../assets/images/cakeshopt.jpg";
import NoResults from "../assets/images/not.svg";

import CakeShop from "../assets/images/shop.jpg";
import UnRegisterdLayout from "../components/layouts/UnRegisterdLayout";
import axiosInstance from "../api/axios";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
// import { Pagination } from "swiper";
import { Navigation } from "swiper";
import "swiper/css/navigation";

import "swiper/css/pagination";
export default function Home() {
  const navigate = useNavigate();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [availableRegions, setAvailableRegions] = useState([]);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [cakes, setCakes] = useState([]);
  const [countryCakes, setCountryCakes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(true);

  const [type, setType] = useState("");

  const [allUsers, setAllUsers] = useState([]);
  const [userName, setUserName] = useState("");
  const [viewOpen, setViewOpen] = useState(false);
  const countries = {
    "Sri Lanka": { currencySymbol: "LKR" },
    "United Kingdom": { currencySymbol: "GBP" },
    Canada: { currencySymbol: "CAD" },
  };
  const handleViewOpen = (id) => {
    setViewOpen(true);
  };
  const handleViewClose = () => {
    setViewOpen(false);
  };

  const handleUserClick = (userType) => {
    navigate(`/signup?userType=${userType}`);
  };
  const regions = {
    "Sri Lanka": [
      "Colombo",
      "Gampaha",
      "Kalutara",
      "Kandy",
      "Matale",
      "Nuwara Eliya",
      "Galle",
      "Matara",
      "Hambantota",
      "Jaffna",
      "Kilinochchi",
      "Mannar",
      "Vavuniya",
      "Mullaitivu",
      "Batticaloa",
      "Ampara",
      "Trincomalee",
      "Kurunegala",
      "Puttalam",
      "Anuradhapura",
      "Polonnaruwa",
      "Badulla",
      "Monaragala",
      "Ratnapura",
      "Kegalle",
    ],
    "United Kingdom": [
      "London",
      "Birmingham",
      "Manchester",
      "Liverpool",
      "Leeds",
      "Newcastle",
      "Sheffield",
      "Bristol",
      "Oxford",
      "Cambridge",
    ],
    Canada: ["Ontario", "Quebec", "British Columbia", "Alberta", "Manitoba"],
  };
  const handleEditClick = () => {
    navigate(`/cakes`);
  };
  // useEffect(() => {
  //   axios
  //     .get("https://geolocation-db.com/json/")
  //     .then((response) => {
  //       const data = response.data;
  //       setUserCountry(data?.country_name);
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching IP:", error);
  //     });
  // }, [userCountry]);
  useEffect(() => {
    getUsers();
    getCakes();
    getCountryCakes();
  }, [country, region, type, userName]);
  const getCakes = async () => {
    try {
      axiosInstance
        .post("/api/cake/find", {
          limit: 6,
          page: 1,
          search_key: {
            country: country,
            region: region,
            price: "",
            type: type,
            user_id: userName,
          },
        })
        .then((response) => {
          const cakes = response?.data?.data?.items;
          setLoading(false);
          setCakes(cakes || []);
        })
        .catch((err) => {
          console.log("error", err);
          setLoading(false);
          return err;
        });
    } catch (err) {
      console.log("error", err);
    }
  };
  const getCountryCakes = async () => {
    try {
      axiosInstance
        .post("/api/cake/find", {
          limit: 18,
          page: 1,
          search_key: {
            country: "",
            region: "",
            price: "",
            type: "",
            user_id: "",
          },
        })
        .then((response) => {
          const cakes = response?.data?.data?.items;
         
          setCountryCakes(cakes || []);
          setLoading2(false);
        })
        .catch((err) => {
          console.log("error", err);
          setLoading2(false);
          return err;
        });
    } catch (err) {
      console.log("error", err);
    }
  };
  const getUsers = async () => {
    try {
      axiosInstance
        .post("/api/user/search", {
          user_name: "",
        })
        .then((response) => {
          const user = response?.data?.data;

          setAllUsers(user || []);
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
    }
  };
  const clearall = () => {
    setCountry("");
    setSelectedCountry("");
    setRegion("");
    setType("");
    setUserName("");
  };
  return (
    <UnRegisterdLayout>
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sm={6}>
            <Grid
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "18px",
                alignItems: { xs: "center", md: "flex-start" },
              }}
            >
              <Typography
                variant="h1"
                sx={{ textAlign: { xs: "center", md: "left" } }}
              >
                Welcome to The HomeCakes{" "}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  maxWidth: "514px",
                  textAlign: { xs: "center", md: "left" },
                }}
              >
                Indulge your sweet cravings with Home Cakes – more than just an
                online marketplace, it's a haven designed for those who
                appreciate the artistry of baking and the joy that comes with
                every decadent bite. 🍰✨
              </Typography>
              <Button
                variant="contained"
                sx={{ maxWidth: "165px" }}
                onClick={handleViewOpen}
              >
                Get Started
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img
              loading="lazy"
              src={Hero}
              alt="hero"
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        {/* <Grid
          sx={{
            mt: "8vh",
            width: "100%",
            mb: "8vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
               <Swiper
          spaceBetween={10}
          slidesPerView={1}
         
          modules={[Pagination]}
          breakpoints={{
          
            1050: {
              slidesPerView: 2.2, // Show only one slide per frame
            },
          }}
        >
         
             
            {allUsers
              ?.filter((user) => user.is_active == true)
              .map((user, index) => (
                <SwiperSlide
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      backgroundColor: "#F9F8F8",
                      boxShadow: "1px 4px 8px 6px rgba(0, 0, 0, 0.25)",
                      padding: "18px",
                      borderRadius: "50px",
                      maxWidth: "300px",
                      width: "100%",
                      margin: "12px",
                    }}
                  >
                    <Avatar
                      src={user?.image}
                      alt={user?.user_name.charAt(0).toUpperCase()}
                      sx={{ width: 75, height: 75 }}
                    />
                    &nbsp; &nbsp;
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "start",
                        justifyContent: "start",
                      }}
                    >
                      <Typography    sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '200px',
              whiteSpace: 'nowrap',
            }} variant="h3">{user?.user_name} </Typography>
                      <Typography variant="h6">Cake Seller </Typography>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        </Grid> */}

        <Grid sx={{ mt: "8vh", mb: "8vh" }}>
          <SearchBar
            search={() => getCakes()}
            regionchange={(e) => {
              setRegion(e.target.value);
            }}
            typechange={(e) => {
              setType(e.target.value);
            }}
            type={type}
            selectedCountry={selectedCountry}
            region={region}
            countries={countries}
            availableRegions={availableRegions}
            countryonchange={(e) => {
              const selectedCountry = e.target.value;
              setSelectedCountry(selectedCountry);
              setCountry(selectedCountry);
              setAvailableRegions(regions[selectedCountry] || []);
            }}
            userChange={(e) => {
              setUserName(e.target.value);
            }}
            user={userName}
            users={allUsers}
            clear={clearall}
          />
        </Grid>
      </Container>

      <Grid className="backimg">
        <Container maxWidth="xl">
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",

                color: "black",
                display: cakes?.length > 0 ? "none" : "flex",
              }}
            >
              {loading ? (
                <img
                  src={Spinner}
                  alt="no results"
                  style={{
                    borderRadius: 8,
                    objectFit: "cover",
                    width: "100%",
                    maxWidth: "350px",
                    height: "auto",
                  }}
                />
              ) : (
                <img
                  src={NoResults}
                  alt="no results"
                  style={{
                    borderRadius: 8,
                    objectFit: "cover",
                    width: "100%",
                    maxWidth: "350px",
                    height: "auto",
                  }}
                />
              )}

              <Typography variant="body1" mt={4}>
                {loading ? "Loading..." : "Sorry! No result found"}
              </Typography>
            </Grid>
            {cakes &&
              cakes?.map((product, index) => (
                <ProductCard
                  key={index}
                  image={product?.images?.image_1_thumb}
                  title={product?.name}
                  para={product?.description}
                  price={product?.price}
                  cakeId={product?.id}
                  usd={product?.currency}
                />
              ))}
          </Grid>

          <Grid sx={{ textAlign: "center", mt: "8vh", mb: "8vh" }}>
            <button
              onClick={handleEditClick}
              className="anotherbutton"
              style={{ background: "#DAD4D6", cursor: "pointer" }}
            >
              View More
            </button>
          </Grid>
        </Container>
      </Grid>
      <Grid className="backimg">
        <Container maxWidth="xl">
          <Typography variant="h2" textAlign={"center"} mb={4} mt={4}>
            {" "}
            Discover Delicious Cakes from the United Kingdom 🇬🇧{" "}
          </Typography>
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",

                color: "black",
                display: countryCakes?.filter((cake) => cake?.country === "United Kingdom").length > 0 ? "none" : "flex",
              }}
            >
             {loading2 ? (
                <img
                  src={Spinner}
                  alt="no results"
                  style={{
                    borderRadius: 8,
                    objectFit: "cover",
                    width: "100%",
                    maxWidth: "350px",
                    height: "auto",
                  }}
                />
              ) : (
                <img
                  src={NoResults}
                  alt="no results"
                  style={{
                    borderRadius: 8,
                    objectFit: "cover",
                    width: "100%",
                    maxWidth: "350px",
                    height: "auto",
                  }}
                />
              )}

              <Typography variant="body1" mt={4}>
                {loading2 ? "Loading..." : "Sorry! No result found"}
              </Typography>
            </Grid>
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                633: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                900: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                1137: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },

                1690: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
              }}
            >
              {countryCakes &&
                countryCakes
                  ?.filter((cake) => cake?.country === "United Kingdom")
                  .map((product, index) => (
                    <SwiperSlide key={index}>
                      {" "}
                      <ProductCard
                        key={index}
                        image={product?.images?.image_1_thumb}
                        title={product?.name}
                        para={product?.description}
                        price={product?.price}
                        cakeId={product?.id}
                        usd={product?.currency}
                      />{" "}
                    </SwiperSlide>
                  ))}
            </Swiper>
          </Grid>
        </Container>
        <Container maxWidth="xl">
          <Typography variant="h2" textAlign={"center"} mb={4} mt={4}>
            {" "}
            Explore Exquisite Cakes from Sri Lanka 🇱🇰{" "}
          </Typography>
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",

                color: "black",
                display: countryCakes?.filter((cake) => cake?.country === "Sri Lanka").length > 0 ? "none" : "flex",
              }}
            >
              <img
                src={NoResults}
                alt="no results"
                style={{
                  borderRadius: 8,
                  objectFit: "cover",
                  width: "100%",
                  maxWidth: "350px",
                  height: "auto",
                }}
              />
              <Typography variant="body1" mt={4}>
                {" "}
                Sorry! No result found{" "}
              </Typography>
            </Grid>
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              navigation={true}
              modules={[Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                633: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                900: {
                  slidesPerView: 2,
                  spaceBetween: 0,
                },
                1137: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },

                1690: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
              }}
            >
              {countryCakes &&
                countryCakes
                  ?.filter((cake) => cake?.country === "Sri Lanka")
                  .map((product, index) => (
                    <SwiperSlide key={index}>
                      {" "}
                      <ProductCard
                        key={index}
                        image={product?.images?.image_1_thumb}
                        title={product?.name}
                        para={product?.description}
                        price={product?.price}
                        cakeId={product?.id}
                        usd={product?.currency}
                      />
                    </SwiperSlide>
                  ))}
            </Swiper>
          </Grid>
        </Container>
      </Grid>
      <Grid className="backimg">
        <Grid sx={{ mt: "8vh", mb: "8vh" }}>
          <Banner
            img={CakeShop}
            title="For Cake Sellers"
            para="Home Cakes is the canvas for your creativity. As a passionate cake artist, you have the opportunity to showcase your culinary masterpieces to a community of enthusiasts who truly value the craftsmanship behind each confection. "
          />
        </Grid>
        <Grid sx={{ mt: "8vh", mb: "8vh" }}>
          <Banner
            directionProp="rtl"
            img={BannerTest}
            title="For Cake Enthusiasts"
            para="Embark on a delightful journey through a curated collection of cakes, each telling a unique story through flavors, designs, and personal touches. Home Cakes is your passport to a world of sweet wonders, where you can explore classic favorites, trendy treats, and hidden gems crafted by passionate bakers."
          />
        </Grid>
        <Grid sx={{ mt: "8vh", mb: "8vh" }}>
          <Banner
            img={Rest}
            title="Sweet Moments Begin Here"
            para="Join our community where bakers thrive and cake lovers unite. Home Cakes is more than a marketplace; it's a celebration of the joy found in every bite, the stories shared around a cake, and the connections that blossom through a shared love of sweet indulgence.

            "
          />
        </Grid>
      </Grid>
      <Dialog onClose={handleViewClose} open={viewOpen}>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "8px",
            width: "100%",
            padding: "20px",
          }}
        >
          <Typography> Select Your User Type</Typography>
          <Grid
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
              width: "100%",
              padding: "20px",
            }}
          >
            <button
              onClick={() => handleUserClick("BUYER_USER")}
              className="anotherbutton"
              style={{ background: "#DAD4D6", cursor: "pointer" }}
            >
              Cake Buyer
            </button>
            <button
              onClick={() => handleUserClick("SELLER_USER")}
              className="anotherbutton"
              style={{ background: "#DAD4D6", cursor: "pointer" }}
            >
              Cake Seller
            </button>
          </Grid>
        </Grid>
      </Dialog>
    </UnRegisterdLayout>
  );
}
