import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import Footer from "../core/Footer";
import { NavBar } from "../core/NavBar";
import SideNav from "../core/SideNav";

import { Box, Typography } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import PageLoader from "./PageLoader";

const layoutStyles = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
};

const contentStyles = {
  flex: 1,
};

const RegisterdLayout = ({ children }) => {

  const [loading, setLoading] = useState(true);
  const storedUserString = localStorage.getItem("user");
  const storedUser = JSON.parse(storedUserString); 
  const link3 = storedUser?.type === "SELLER_USER" ? "/dashboard/add-cake" : null;
  const text3 = storedUser?.type === "SELLER_USER" ? "Add Cakes" : null;
  const link4 = storedUser?.type === "SELLER_USER" ? "/dashboard/myCakes" : null;
  const text4 = storedUser?.type === "SELLER_USER" ? "My Cakes" : null;

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
  if (storedUser?.type !== "SELLER_USER" && storedUser?.type !== "BUYER_USER") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bgcolor="#A14364"
      >
        <Box textAlign="center" color="#FFFFFF">
          <ErrorOutline fontSize="large" />
          <Typography variant="h4" component="h1" gutterBottom>
            You don't have permission to access this page.
          </Typography>
        </Box>
      </Box>
    );
  
  }
  return (
    <div style={layoutStyles}>
      <NavBar userdash={true} />
      <Container maxWidth="xl">
        <Grid container marginTop="4%" marginBottom="4%">
          <Grid item xs={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <SideNav
              link1="/dashboard"
              text1="Dashboard"
              link2="/dashboard/profile"
              text2="My Profile"
              link3={link3}
              text3={text3}
              link4={link4}
              text4={text4}
            />
          </Grid>
          <Grid item xs={0.5} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
          <Grid item xs={12} sm={9.5} style={contentStyles}>
          {loading && <PageLoader />}   {children}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default RegisterdLayout;
