import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/layouts/AdminLayout";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import axiosInstance from "../../api/axios";
import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function AdminCakes() {
  const [Cakes, setCakes] = useState([]);
  const [loggedInUserCountry, setLoggedInUserCountry] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    getCakes();
    getLoggedUser();
  }, [loggedInUserCountry,Cakes]);
  const getLoggedUser = async () => {
    try {
      axiosInstance
        .get("/api/user/current", {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          const user = response?.data?.data;
          setLoggedInUserCountry(user?.country);
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
      if (!err?.response) {
        toast.error("User not fount");
        return;
      }
    }
  };
  function toDate(str) {
    let test = new Date(str.replace("T", " ").slice(0, -1));

    return test;
  }
  const getCakes = async () => {
    try {
      axiosInstance
        .post("/api/cake/getAll", {
          limit: 1000,
          page: 1,
        })
        .then((response) => {
          console.log('response', response)
       
          const Cakes = response?.data?.data?.items;
          if (loggedInUserCountry && loggedInUserCountry !== "ALL") {
        
            const filteredCakes = Cakes.filter(
              (cake) => cake?.country === loggedInUserCountry
            );
            setCakes(filteredCakes);
          } else if(loggedInUserCountry) {
          
            setCakes(Cakes || []);
          }
        
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
    }
  };

  const CakeColumns = [
    { field: "oder", headerName: "ID", width: 50 },
    {
      field: "image",
      headerName: "Image",
      width: 50,
      editable: false,
      renderCell: (params) => {
        return (
          <Avatar
            key={params?.row.id}
            alt={params?.row.name.charAt(0)}
            src={params?.row.image}
            style={{ width: 35, height: 35, textTransform: "capitalize" }}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 100,
      editable: false,
    },
    {
      field: "price",
      headerName: "Price",
      width: 80,
      editable: false,
    },
    {
      field: "type",
      headerName: "Type",

      width: 100,
      editable: false,
    },

    {
      field: "weight",
      headerName: "weight",

      width: 100,
      editable: false,
    },
    {
      field: "country",
      headerName: "Country",
      width: 120,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
      editable: false,
      renderCell: (params) => {
        const status = params.row.status;

        // Customize the appearance of the Chip based on the status
        let chipColor = "default";
        if (status === "APPROVED") {
          chipColor = "success";
        } else if (status === "PENDING") {
          chipColor = "warning";
        } else if (status === "REJECTED") {
          chipColor = "error";
        }

        return (
          <Chip
            label={status}
            color={chipColor}
            variant="outlined"
            size="small"
          />
        );
      },
    },
    {
      field: "user",
      headerName: "User",
      width: 100,
      editable: false,
    },
    {
      field: "View",
      headerName: "View",
      width: 80,
      editable: false,
      renderCell: (params) => {
        const id = params.row.id;
        return (
          <Chip
            label={"View"}
            color="primary"
            variant="contained"
            size="small"
            onClick={() => {
              navigate(`/single-cake?id=${id}`);
            }}
          />
        );
      },
    },
    {
      field: "created_at",
      headerName: "Created Date",
      width: 150,
      editable: false,
    },
  ];
  const CakeRows = Cakes?.map((Cake, index) => ({
    id: Cake.id,
    oder: index + 1,
    image: Cake.images?.image_1,
    name: Cake.name,
    price: Cake.price,
    type: Cake.type,
    weight: Cake.weight,
    country: Cake.country,
    region: Cake.region,
    status: Cake.status,
    user: Cake.user.user_name,
    is_active: Cake.is_active,
    is_disabled: Cake.is_disabled,
    created_at: toDate(Cake.created_at),
  }));
  return (
    <AdminLayout>
      <DataGrid
        rows={CakeRows}
        style={{ fontSize: "14px" }}
        columns={CakeColumns}
        pageSize={10}
        autoPageSize={false}
        rowsPerPageOptions={[10]}
        slots={{ toolbar: GridToolbar }}
        experimentalFeatures={{ newEditingApi: true }}
      />
    </AdminLayout>
  );
}
