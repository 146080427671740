import { useState } from 'react';
import React, { useContext } from "react";
import Menu from "../../assets/icons/menu.svg";
import Close from "../../assets/icons/close.svg";
import { Link } from 'react-router-dom';
import { Grid  ,Button, Drawer, List, ListItem, Typography } from '@mui/material';
import { AuthContext } from "../context/AuthContext";


function MobileNav(props) {
  const [open, setOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const storedUserString = localStorage.getItem("user");
  const storedUser = JSON.parse(storedUserString);

  const toggleDrawer = () => {
    setOpen(!open); 
  };
  const drawerWidth = "50%";

  return (
    <>
               

      <Button onClick={toggleDrawer}> <img loading="lazy" src={Menu} alt="Menu icon" /></Button>
      <Drawer anchor="right" open={open} onClose={toggleDrawer}  PaperProps={{
        style: {
          width: drawerWidth,
        },
      }} >
        <Grid sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>

        <Grid sx={{display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'center'}}>
        <Button onClick={toggleDrawer} ><img loading="lazy" src={Close} alt="Menu" /></Button>
        </Grid>
    
        <Grid>
        <List >
          <ListItem >
          <Link  to="/" ><Typography variant="body2"> Home</Typography></Link>
     
          </ListItem>
          <ListItem >
          <Link  to="/About" ><Typography variant="body2"> About</Typography></Link>
        
          </ListItem>
          <ListItem >
          <Link  to="/Contact" ><Typography variant="body2"> Contact</Typography></Link>
        
          </ListItem>
   
          <ListItem >
          <Link  to="/Cakes" ><Typography variant="body2"> Cakes</Typography></Link>

        
          </ListItem>        
       
            

          
       
        </List>
        <Link to={(storedUser?.type === "SUPER_ADMIN" || storedUser?.type ==="MAINTAINER")?"/admin-dashboard":(storedUser?.type === "SELLER_USER" || storedUser?.type === "BUYER_USER")?"/dashboard":"/login"}>
             {" "}
             <Button variant="contained" sx={{maxWidth:'150px'}}>{user?'Dashboard':'Register Now'}</Button>{" "}
           </Link>
        </Grid>
    
        </Grid>
      </Drawer>
    </>
  );
}

export default MobileNav;