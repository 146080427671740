import React from "react";
import { Button, Grid ,Typography } from "@mui/material";
import { Link } from "react-router-dom";
import Image from "../../assets/images/404.svg";
import UnRegisterdLayout from "../layouts/UnRegisterdLayout";

const NotFound = () => (
    <UnRegisterdLayout> 
    <Grid container sx={{gap:'18px', padding: "3%" ,display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    <img
      loading="lazy"
      src={Image}
      alt="React Logo"
      style={{ width: "100%", maxWidth: "800px", margin: "0 auto" }}
    />
      <Typography variant="h4">404 - Not Found</Typography>
    <Typography variant="body2">The requested page could not be found.</Typography>
    <Link to={"/"}>  <Button variant="contained">Back to Home</Button></Link>
  </Grid>
  </UnRegisterdLayout>
);

export default NotFound;