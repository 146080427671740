import { Card, Button, TextField,  IconButton,
} from "@mui/material";
import React, { useState } from "react";
import { Container } from "@mui/system";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import UnRegisterdLayout from "../components/layouts/UnRegisterdLayout";
import axiosInstance from "../api/axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const reset = async (event) => {
    if (!token) {
      toast.error("Invalid Request");
      return;
    }
    event.preventDefault();
    const toastId = toast.loading("Please wait...");
    try {
      const response = await axiosInstance.post("/api/auth/resetPassword", {
        password,token
       
      });
      if (response.status === 200) {
        toast.update(toastId, {
          render: "Password Set  successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      
        navigate("/login");
       
      }   if (response.status === 201) {
        toast.update(toastId, {
          render: response?.data.message,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      
    
       
      }else {
        // Handle login error
      }
    } catch (error) {
      toast.update(toastId, {
        render: error?.response?.data,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <UnRegisterdLayout> 
    <Container maxWidth="lg" sx={{ mt: "8vh", mb: "8vh" }}>
      <Card
        sx={{
          backgroundColor: "#ECEBEB",

          textAlign: "center",
        }}
      >
        <div
          className="reset"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-end",
            padding: "20%",
            gap: "20px",
          }}
        >
          
          <TextField
               sx={{ height: "57px", width: "100%", maxWidth: "440px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "black" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? (
                        <VisibilityIcon sx={{ color: "black" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: "black" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: "#FFFFFF",
                  borderRadius: "15px",
                  borderColor: "#EE548A",
                  fontSize: "16px",
                  height: "57px",
                
                },
              }}
              placeholder="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => {
                const enteredPassword = e.target.value;
                setPassword(enteredPassword);
                setPasswordError(
                  !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,12}$/.test(
                    enteredPassword
                  )
                );
              }}
              error={passwordError}
              helperText={passwordError && "Invalid password"}
            />
           <TextField
                sx={{ height: "57px", width: "100%", maxWidth: "440px" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "black" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                      aria-label="toggle confirm password visibility"
                    >
                      {showConfirmPassword ? (
                        <VisibilityIcon sx={{ color: "black" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: "black" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: "#FFFFFF",
                  borderRadius: "15px",
                  borderColor: "#EE548A",
                  fontSize: "16px",

                
                },
              }}
              placeholder="Confirm Password"
              variant="outlined"
              type={showConfirmPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => {
                const enteredConfirmPassword = e.target.value;
                setConfirmPassword(enteredConfirmPassword);
                setConfirmPasswordError(enteredConfirmPassword !== password);
              }}
              error={confirmPasswordError}
              helperText={confirmPasswordError && "Passwords do not match"}
            />
          <Button variant="contained" sx={{maxWidth:'250px'}} onClick={reset} disabled={passwordError||confirmPasswordError}>
          Update 
          </Button>
        </div>
      </Card>
    </Container>
    </UnRegisterdLayout>
  );
}
