import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import About from "../../pages/About";
import Cakes from "../../pages/Cakes";
import Contact from "../../pages/Contact";
import CreateAccount from "../../pages/CreateAccount";
import Dashboard from "../../pages/Dashboard/Dashboard";
import Home from "../../pages/Home";
import Login from "../../pages/Login";
import Reset from "../../pages/resetpassword";
import VerifyAccount from "../../pages/VerifyAccount";
import { useNavigate } from "react-router-dom";
import Profile from "../../pages/Dashboard/Profile";
import ChangePass from "../../pages/Dashboard/ChangePass";
import MyCakes from "../../pages/Dashboard/MyCakes";
import SingleCake from "../../pages/SingleCake";
import AddCake from "../../pages/Dashboard/AddCake";
import AdminHome from "../../pages/Admin/Home";
import Users from "../../pages/Admin/Users";
import AdminCakes from "../../pages/Admin/Cakes";
import Requests from "../../pages/Admin/Requests";
import AdminProfile from "../../pages/Admin/AdminProfile";
import AdminChangePass from "../../pages/Admin/AdminChangePass";
import NotFound from "../../components/core/NotFound";
import TermsAndConditions from "../../pages/TermsAndCondtions";
import PrivacyPolicy from "../../pages/PrivacyPolicy";
import Admins from "../../pages/Admin/Admins";
function PrivateRoute({ element }) {
  const storedUserString = localStorage.getItem("user");
  const storedUser = JSON.parse(storedUserString);
  const navigate = useNavigate();
 
  useEffect(() => {
    if (storedUser?.type !== "SELLER_USER" && storedUser?.type !== "BUYER_USER") {
      navigate("/login");
    }
  }, [navigate, storedUser.type]);

  return storedUser?.type === "SELLER_USER" || storedUser?.type === "BUYER_USER"
    ? element
    : null;
}

function AdminRoute({ element }) {
  const storedUserString = localStorage.getItem("user");
  const storedUser = JSON.parse(storedUserString);


  const navigate = useNavigate();

  useEffect(() => {
    // Use useEffect to call navigate()
    if (storedUser?.type !== "SUPER_ADMIN" && storedUser?.type !== "MAINTAINER") {
      navigate("/");
    }
  }, [navigate, storedUser.type]);

  return storedUser?.type === "SUPER_ADMIN" || storedUser?.type === "MAINTAINER"
    ? element
    : null; // Return null if not authorized
}
function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/terms-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/signup" element={<CreateAccount />} />
      <Route path="/resetpassword" element={<Reset />} />
      <Route path="/cakes" element={<Cakes />} />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/verifyAccount" element={<VerifyAccount />} />
      <Route path="/single-cake" element={<SingleCake />} />
      <Route
        path="*"
        element={<NotFound />}
      />
      <Route
        path="/admin-dashboard"
        element={<AdminRoute element={<AdminHome />} />}
      />
      <Route
        path="/admin-dashboard/users"
        element={<AdminRoute element={<Users />} />}
      />
            <Route
        path="/admin-dashboard/admins"
        element={<AdminRoute element={<Admins />} />}
      />
      <Route
        path="/admin-dashboard/cakes"
        element={<AdminRoute element={<AdminCakes />} />}
      />
      <Route
        path="/admin-dashboard/requests"
        element={<AdminRoute element={<Requests />} />}
      />
        <Route
        path="/admin-dashboard/profile"
        element={<AdminRoute element={<AdminProfile/>} />}
      />
      <Route
        path="/admin-dashboard/changePass"
        element={<AdminRoute element={<AdminChangePass/>} />}
      />
      <Route
        path="/dashboard"
        element={<PrivateRoute element={<Dashboard />} />}
      />

      <Route
        path="/dashboard/add-cake"
        element={<PrivateRoute element={<AddCake />} />}
      />
      <Route
        path="/dashboard/profile"
        element={<PrivateRoute element={<Profile />} />}
      />
      <Route
        path="/dashboard/changePass"
        element={<PrivateRoute element={<ChangePass />} />}
      />
      <Route
        path="/dashboard/myCakes"
        element={<PrivateRoute element={<MyCakes />} />}
      />
   
        
    </Routes>
  );
}

export default AppRouter;
