import React,{useEffect,useState} from "react";
import Footer from "../core/Footer";
import { NavBar } from "../core/NavBar";
import PageLoader from "./PageLoader";
const layoutStyles = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
};

const contentStyles = {
  flex: 1,
};

const UnRegisterdLayout = ({ children }) => {
    const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
  return (
    <div style={layoutStyles}>
      <NavBar />
      <div style={contentStyles}>   {loading && <PageLoader />} {children}</div>
      <Footer />
    </div>
  );
};

export default UnRegisterdLayout;
