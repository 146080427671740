import {
  Card,
  Grid,
  TextField,
  FormControl,
 
  Select,
  MenuItem,
  Container,
  IconButton,
} from "@mui/material";

import React from "react";
import ClearIcon from '@mui/icons-material/Clear';
export default function SearchBar({
  selectedCountry,
  countryonchange,
  region,
  countries,
  regionchange,
  availableRegions,
  typechange,
  type,
  userChange,
  user,
  users,
  clear
}) {
  return (
    <Container maxWidth="lg">
      <Card>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            flexDirection: { xs: "column", md: "row" },
            padding: "15px",
            gap: "8px",
            backgroundColor: "#FCDDE8",
          }}
        >
          <FormControl sx={{ width: "100%", maxWidth: "220px" }}>
            <Select
              value={selectedCountry?selectedCountry:"select"}
              onChange={countryonchange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "15px",
                borderColor: "#EE548A",
                height: "44px",
                color:selectedCountry?"":'#BCBCBC !important'
              }}
            >
              <MenuItem value="select" disabled >
              Search by country
              </MenuItem>
              {Object.keys(countries).map((country) => (
                <MenuItem key={country} value={country}>
                  {country}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%", maxWidth: "220px" }}>
            <Select
              value={region?region:"select"}
              onChange={regionchange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "15px",
                borderColor: "#EE548A",
                height: "44px",
                color:selectedCountry?"":'#BCBCBC !important'
              }}
            >
               <MenuItem value="select" disabled>
              Search by Region
              </MenuItem>
              {availableRegions?.map((region) => (
                <MenuItem key={region} value={region}>
                  {region}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl sx={{ width: "100%", maxWidth: "220px" }}>
          <TextField
            InputProps={{
              sx: {
                backgroundColor: "#FFFFFF",
                borderRadius: "15px",
                borderColor: "#EE548A",
                height: "44px",
                
              },
            }}
            value={type}
            onChange={typechange}
            placeholder="Search by Type"
            variant="outlined"
          />
          </FormControl>
          <FormControl sx={{ width: "100%", maxWidth: "220px" }}>
            <Select
              value={user?user:"select"}
              onChange={userChange}
              sx={{
                backgroundColor: "#FFFFFF",
                borderRadius: "15px",
                borderColor: "#EE548A",
                height: "44px",
                color:user?"":'#BCBCBC !important'
              }}
            >
               <MenuItem value="select" disabled>
              Search by User
              </MenuItem>
              {users?.map((user) => (
                <MenuItem key={user?.id} value={user?.id}>
                  {user?.user_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <IconButton
            sx={{
              borderRadius: "50%",
              backgroundColor: "#EE548A",
              padding: "1%",
            }}
            onClick={clear}
          >
            <ClearIcon color="inherit" />
          </IconButton>
        </Grid>
      </Card>
    </Container>
  );
}
