import { Grid, Typography, Container } from "@mui/material";
import React from "react";
import { Link } from 'react-router-dom';

export default function Banner({ img, title, para,directionProp }) {
  return (
    <Container maxWidth="lg">
      <Grid container className="bannermain" sx={{flexDirection: directionProp === 'rtl' ? 'row-reverse' : 'row'}}>
       <Grid item xs={12} sm={5}>
       
        <img loading="lazy" src={img} alt="banner" style={{width:'100%',height:'auto',objectFit:'cover',borderRadius:'25px'}}  />
       
     
       </Grid>
       
       
      
        <Grid item xs={12} sm={7} sx={{ display:'flex',flexDirection:'column',justifyContent:'center',alignItems:directionProp === 'rtl' ?'flex-start':'center',gap:"26px",padding:'28px'}}>
          <Typography variant="banner"sx={{color: "#FFFFFF" ,maxWidth:'400px'}}>
            {title}
          </Typography>
          <Typography variant="h3"  sx={{color: "#FFFFFF",maxWidth:'400px'}}>
            {para}
          </Typography>
          <div style={{alignSelf:directionProp === 'rtl' ?'flex-start':'end'}}>
          <button className="anotherbutton"  style={{ background: "#FFFFFF",float: 'right' }}>
          <Link to="/cakes">Search</Link>
          </button>
          </div>
         
        </Grid>
       
      </Grid>
    </Container>
  );
}
