import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import {
  Button,
  Chip,
  Rating,
  CardContent,
  IconButton,
  Modal,
  Backdrop,
  Grid,
  Typography,
  Dialog,
  Card,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Avatar from "@mui/material/Avatar";
import UnRegisterdLayout from "../components/layouts/UnRegisterdLayout";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../api/axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/swiper.min.css";
import "swiper/css/navigation";
import WriteReview from "../components/cards/WriteReview";
import WhatsappIcon from "../../src/assets/icons/whatsapp.png";
import Telephone from "../../src/assets/icons/telephone.png";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import DeleteIcon from "@mui/icons-material/Delete";
export default function SingleCake() {
  const [searchParams] = useSearchParams();
  const cakeId = searchParams.get("id");

  const [cakeName, setCakeName] = useState("");
  const [price, setPrice] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);
  const [type, setType] = useState("");
  const [weight, setWeight] = useState("");
  const [instructions, setInstructions] = useState("");
  const [dec, setDec] = useState("");
  const [name, setName] = useState("");
  const [viewOpen, setViewOpen] = useState(false);
  const [rate, setRate] = useState("");
  const [review, setReview] = useState("");
  const [userDp, setUserDp] = useState("");
  const [userNum, setUserNum] = useState("");

  const [currency, setCurrency] = useState("");
  const [reviews, setReviews] = useState([]);

  const [whatsapp, setWhatsapp] = useState("");
  const [islogin, setIslogin] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [reviewEdit, setReviewEdit] = useState(false);
  const [reviewId, setReviewId] = useState("");

  const handleViewOpen = (id) => {
    setViewOpen(true);
  };
  const handleEditOpen = (reviews) => {
    setRate(reviews?.star);
    setReviewId(reviews?.id);
    setReview(reviews?.description);
    setReviewEdit(true);
    setViewOpen(true);
  };
  const handleViewClose = () => {
    setViewOpen(false);
  };
  const getLoggedUser = async () => {
    try {
      axiosInstance
        .get("/api/user/current", {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          const user = response?.data?.data;

          setIslogin(user?.user_name ? true : false);
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
      if (!err?.response) {
        toast.error("User not fount");
        return;
      }
    }
  };
  const writeReview = async (id) => {
    const toastId = toast.loading("Please wait...");
    if (!review) {
      toast.update(toastId, {
        render: "Please Add An decscription ",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }
    try {
      reviewEdit
        ? await axiosInstance
            .patch("/api/review/edit", {
              description: review,
              star: rate,
              id: reviewId,
            })
            .then(async (response) => {
              toast.update(toastId, {
                render: "Review Edit successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
              });
              handleViewClose();
              getReviews(cakeId);
              setReview("");
              setRate("");
            })
            .catch((err) => {
              console.log("error", err);
              toast.update(toastId, {
                render: "Review Edit Error!",
                type: "error",
                isLoading: false,
                autoClose: 3000,
              });
              return err;
            })
        : await axiosInstance
            .post("/api/review/add", {
              description: review,
              star: rate,
              cake_id: cakeId,
            })
            .then(async (response) => {
            
              toast.update(toastId, {
                render: "Review Added successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
              });
              setReview("");
              setRate("");
              handleViewClose();
              getReviews(cakeId);
            })

            .catch((err) => {
              console.log("error", err);
              toast.update(toastId, {
                render: "Review Add Error!",
                type: "error",
                isLoading: false,
                autoClose: 3000,
              });
              return err;
            });
    } catch (err) {
      console.log("error", err);
      toast.update(toastId, {
        render: "Review Added  error!",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      if (!err?.response) {
        toast.error("User not fount");
        return;
      }
    }
  };

  const firstLetter = name ? name.charAt(0).toUpperCase() : "";
  const getSingleCake = async (cakeId) => {
    try {
      axiosInstance
        .post("/api/cake/getById", {
          id: cakeId,
        })
        .then(async (response) => {
          const Cake = response?.data?.data;
          setCakeName(Cake?.name);
          setPrice(Cake?.price);
          setCountry(Cake?.country);
          setRegion(Cake?.region);
          setCurrency(Cake?.currency);
          setIngredients(Cake?.ingredients);
          setWhatsapp(Cake?.whatsapp_number.slice(3));
          const cakeImages = Cake?.images;
          const updatedImagesObject = { ...imagesArray };
          Object.keys(cakeImages).forEach((key) => {
            if (!key.includes("_thumb")) {
              const nonThumbKey = key;
              const originalKey = key.replace(/_thumb$/, "");
              updatedImagesObject[originalKey] = cakeImages[nonThumbKey];
            }
          });
          setImagesArray(updatedImagesObject);

          setType(Cake?.type);
          setWeight(Cake?.weight);
          setInstructions(Cake?.special_instructions);
          setDec(Cake?.description);
          setUserDp(Cake?.user?.image || "");
          setUserNum(Cake?.user?.phone_number);

          setName(Cake?.user?.user_name);
        })

        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
      if (!err?.response) {
        toast.error("User not fount");
        return;
      }
    }
  };
  const getReviews = async (id) => {
    try {
      axiosInstance
        .post("/api/review/ByCakeId", {
          id: id,
          limit: 5,
        })
        .then(async (response) => {
          setReviews(response?.data?.data?.reviews);
        })

        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
      if (!err?.response) {
        toast.error("User not fount");
        return;
      }
    }
  };
  
  const deleteReview = async (id) => {
    const toastId = toast.loading("Please wait...");
    try {
      axiosInstance
        .post("/api/review/delete", {
          id: id,
         
        })
        .then(async (response) => {
          toast.update(toastId, {
            render: "Review Delete successfully!",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
          getReviews(cakeId);
        })
   
        .catch((err) => {
          console.log("error", err);
          toast.update(toastId, {
            render: "Review Delete Error!",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          return err;
        });
    } catch (err) {
      console.log("error", err);
      if (!err?.response) {
        toast.error("User not fount");
        return;
      }
    }
  };
  useEffect(() => {
    if (cakeId) {getSingleCake(cakeId);}
    getLoggedUser();
    getReviews(cakeId);
  }, [cakeId]);
  return (
    <UnRegisterdLayout>
      <Container
        maxWidth="xl"
        sx={{ display: "flex", flexDirection: "column", gap: "98px" }}
      >
        <Grid
          sx={{
            backgroundColor: "#ECEBEB ",
            textAlign: "center",
            padding: "2%",
          }}
        >
          <Typography
            variant="h1"
            sx={{ mt: "0.5%", mb: "0.5%", fontWeight: 400 }}
          >
            {cakeName}{" "}
          </Typography>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            loop={true}
            autoplay={{ delay: 5000 }}
            navigation={true} 
            modules={[Navigation]}
          >
            {Object.entries(imagesArray)
              .filter(([key, value]) => value !== "")
              .map(([key, value], index) => (
                <SwiperSlide key={key}>
                  <Grid
                    xs={10}
                    sx={{
                      width: "100%",

                      height: { xs: "300", sm: "600px" },
                      overflow: "hidden",
                      borderRadius: "18px",
                      border: "9px solid #FFF",
                      backgroundColor: "white",
                      background: "lightgray 50%",
                    }}
                  >
                    <img
                      src={value}
                      key={key}
                      alt={`image_${index}`}
                      onClick={() => {
                        setSelectedImage(value);
                        setModalOpen(true);
                      }}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        objectPosition: "center",
                        cursor: "pointer",
                      }}
                      layout="responsive"
                    />
                  </Grid>
                </SwiperSlide>
              ))}
          </Swiper>
          <br></br> <br></br>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0px",
            }}
          >
            <Typography>Location</Typography>
            <Typography>
              {region}&nbsp;,&nbsp;{country}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0px",
            }}
          >
            <Typography>Price</Typography>
            <Chip
              label={`${price}${currency}`}
              variant="filled"
              color="primary"
              style={{ padding: "18px", fontSize: "24px" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0px",
            }}
          >
            <Typography>Type</Typography>
            <Chip
              label={type}
              variant="outlined"
              color="primary"
              style={{ padding: "18px", fontSize: "24px" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0px",
            }}
          >
            <Typography>Weight</Typography>
            <Chip
              label={`${weight}g `}
              variant="filled"
              color="primary"
              style={{ padding: "18px", fontSize: "24px" }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0px",
            }}
          >
            <Typography>Ingredients</Typography>
            <Grid
              sx={{
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {ingredients?.map((ingredient, index) => (
                <Chip
                  key={index}
                  label={ingredient}
                  variant="filled"
                  color="secondary"
                  sx={{ margin: "4px" }}
                />
              ))}{" "}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={12}
            sm={5.5}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0px",
            }}
          >
            <Typography>Description</Typography>
            <Typography sx={{ textAlign: "center" }}>{dec}</Typography>
          </Grid>
          <Grid item xs={12} sm={1}></Grid>
          <Grid
            item
            xs={12}
            sm={5.5}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px 0px",
            }}
          >
            <Typography>Special Instructions</Typography>
            <Typography sx={{ textAlign: "center" }}>{instructions}</Typography>
          </Grid>
          <Grid xs={12} height="auto">
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              centeredSlides={true} 
          
              loop={true}
              autoplay={{ delay: 5000 }}
              navigation={true} 
              modules={[Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                633: {
                  slidesPerView: 1,
                  spaceBetween: 0,
                },
                900: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
                1137: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },

                1690: {
                  slidesPerView: 3,
                  spaceBetween: 0,
                },
              }}
            >
              {reviews?.map((review, index) => (
                <SwiperSlide key={index}>
                  <Grid
                    sx={{
                      width: "100%",
                      padding: "20px",
                      overflow: "hidden",
                      borderRadius: "18px",
                      border: "9px solid #FFF",
                    }}
                  >
                    

                    <Card
                      sx={{
                        maxWidth: "619px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "2px",
                        padding: "8px",
                      }}
                    >
                     
                      <Rating
                        name="read-only"
                        sx={{ fontSize: "30px" }}
                        value={review?.star}
                        readOnly
                      />
                      <CardContent>
                        <Typography variant="body3" color="text.secondary">
                          {review?.description}
                        </Typography>{" "}
                      </CardContent>
                      <div style={{ display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",}}>
                        <Avatar
                          src={review?.user?.image}
                          alt={review?.user?.user_name.charAt(0).toUpperCase()}
                          sx={{ width: 75, height: 75 }}
                        />
                        <Typography variant="h3">
                          {review?.user?.user_name}{" "}
                        </Typography>
                      </div>
                      <div
                      style={{
                        float: "right",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <IconButton>
                        <DeleteIcon style={{ color: "red" }} onClick={() => deleteReview(review?.id)} />
                      </IconButton>
                      <IconButton>
                        <ModeEditOutlineIcon
                          onClick={() => handleEditOpen(review)}
                        />
                      </IconButton>
                    </div>
                    </Card>
                  </Grid>
                </SwiperSlide>
              ))}
            </Swiper>
          </Grid>
          <Grid
            container
            sx={{
              mt: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              mb: "20px",
            }}
          >
            {islogin ? (
              <>
                <Button
                  variant="contained"
                  sx={{
                    maxWidth: "250px",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  onClick={handleViewOpen}
                >
                  Write A Review{" "}
                </Button>

                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "colmun", sm: "row" },
                    alignItems: "center",
                    justifyContent: "space-between",
                    maxWidth: "300px",
                    width: "100%",
                  }}
                >
                  <a
                    href={`https://api.whatsapp.com/send?phone=${whatsapp} `}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={WhatsappIcon}
                      style={{ maxWidth: "80px" }}
                      alt="wahstapp"
                    />
                  </a>
                  <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}> 
                    <Avatar
                      src={userDp}
                      alt={firstLetter}
                      sx={{ width: 75, height: 75 }}
                    >
                      {!userDp && firstLetter}
                    </Avatar>
                   
                  </div>
               
                  <a
                    href={`tel:${userNum} `}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={Telephone}
                      style={{ maxWidth: "80px" }}
                      alt="wahstapp"
                    />
                  </a>
                </Grid>
                <Typography>{name}</Typography>
              </>
            ) : (
              <Grid
                container
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  mb: "20px",
                  mt: "40px",
                }}
              >
                {" "}
                <a href="/login">
                  {" "}
                  <Typography variant="h3">
                    Please Login To View Cake User Details
                  </Typography>
                </a>{" "}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Container>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <IconButton></IconButton>
          <IconButton
            onClick={() => setModalOpen(false)}
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              zIndex: 101, // Higher than the image's zIndex
            }}
          >
            <Close sx={{ fontSize: "36px" }} />
          </IconButton>
          <img
            src={selectedImage}
            alt="Enlarged"
            style={{
              maxWidth: "80vw",
              maxHeight: "80vh",
              display: "block",
              zIndex: 101, // Higher than the image's zIndex
            }}
          />
        </div>
      </Modal>
      <Dialog onClose={handleViewClose} open={viewOpen}>
        <WriteReview
          cancel={handleViewClose}
          reviewchange={(event) => setReview(event.target.value)}
          review={review}
          write={writeReview}
          ratechange={(event) => setRate(event.target.value)}
          ratevalue={rate}
          text={reviewEdit ? "Update" : "Write"}
        />
      </Dialog>
    </UnRegisterdLayout>
  );
}
