import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Chip,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import RegisterdLayout from "../../components/layouts/RegisterdLayout";
import axiosInstance from "../../api/axios";

import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import { Autocomplete } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';
import {
  CountrySelector,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import "react-international-phone/style.css";
import { useNavigate } from "react-router-dom";
export default function AddCake() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const cakeId = searchParams.get("id");
  const [cakeName, setCakeName] = useState("");
  const [price, setPrice] = useState("");
  const [currency, setCurrency] = useState("");
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [ingredients, setIngredients] = useState([]);
  const [imagesObject, setImagesObject] = useState([]);
  
  const [type, setType] = useState("");
  const [weight, setWeight] = useState("");
  const [instructions, setInstructions] = useState("");
  const [dec, setDec] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [flag, setFlag] = useState("gb");
  const [wbNumber, setWbNumber] = useState("+44");
  const [phoneCode, setPhoneCode] = useState("+44");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [availableRegions, setAvailableRegions] = useState([]);
  const countries = {
    "Sri Lanka": { currencySymbol: "LKR" },
    "United Kingdom": { currencySymbol: "GBP" },
    "Canada": { currencySymbol: "CAD" },
  };
  const countryCode = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ["ca", "lk", "gb"].includes(iso2);
  });

  const regions = {
    "Sri Lanka": [
      "Colombo",
      "Gampaha",
      "Kalutara",
      "Kandy",
      "Matale",
      "Nuwara Eliya",
      "Galle",
      "Matara",
      "Hambantota",
      "Jaffna",
      "Kilinochchi",
      "Mannar",
      "Vavuniya",
      "Mullaitivu",
      "Batticaloa",
      "Ampara",
      "Trincomalee",
      "Kurunegala",
      "Puttalam",
      "Anuradhapura",
      "Polonnaruwa",
      "Badulla",
      "Monaragala",
      "Ratnapura",
      "Kegalle",
    ],
    "United Kingdom": [
      "London",
      "Birmingham",
      "Manchester",
      "Liverpool",
      "Leeds",
      "Newcastle",
      "Sheffield",
      "Bristol",
      "Oxford",
      "Cambridge",
    ],
    Canada: ["Ontario", "Quebec", "British Columbia", "Alberta", "Manitoba"],
  };
  const [selectedUnit, setSelectedUnit] = useState("g");

  const handleWeightChange = (e) => {
    setWeight(e.target.value);
  };

  const handleUnitChange = (e) => {
    setSelectedUnit(e.target.value);
    setWeight(`${weight.split(" ")[0]} ${e.target.value}`);
  };
  const fileInputRef = useRef(null);
  const getLoggedUser = async () => {
    try {
      axiosInstance
        .get("/api/user/current", {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          const user = response?.data?.data;
          setName(user?.user_name);
          setEmail(user?.email);
          setPhone(user?.phone_number);
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
      if (!err?.response) {
        toast.error("User not fount");
        return;
      }
    }
  };

  const getSingleCake = async (cakeId) => {
    try {
      axiosInstance
        .post("/api/cake/getById", {
          id: cakeId,
        })
        .then(async (response) => {
          const Cake = response?.data?.data;
     
          setCakeName(Cake?.name);
          setPrice(+Cake?.price);
          setSelectedCountry(Cake?.country);
          setCountry(Cake?.country);
          setAvailableRegions(regions[Cake?.country]);
          setCurrency(Cake?.currency);
          setRegion(Cake?.region);
          setIngredients(Cake?.ingredients);
          const cakeImages = Cake?.images;
          const updatedImagesObject = { ...imagesObject };
          Object.keys(cakeImages).forEach((key) => {
            if (!key.includes("_thumb")) {
              const nonThumbKey = key;
              const originalKey = key.replace(/_thumb$/, "");
              updatedImagesObject[originalKey] = cakeImages[nonThumbKey];
            }
          });
          setImagesObject(updatedImagesObject);
          setType(Cake?.type);
          setWeight(Cake?.weight);
          setInstructions(Cake?.special_instructions);
          setDec(Cake?.description);
          setWbNumber(Cake?.whatsapp_number);
          const phonePrefix = Cake?.whatsapp_number?.substring(0, 3);
          if (phonePrefix === "+44") {
            setFlag("gb");
          } else if (phonePrefix === "+1") {
            setFlag("ca");
          } else if (phonePrefix === "+94") {
            setFlag("lk");
          }
        });
    } catch (err) {
      console.log("error", err);
      if (!err?.response) {
        toast.error("User not fount");
        return;
      }
    }
  };
  const replaceImage = (key) => {
    // Create a reference to the file input
    const fileInput = document.createElement("input");
    fileInput.type = "file";
  
    // Listen for the change event when a file is selected
    fileInput.addEventListener("change", async (event) => {
      const files = event.target.files;
      if (files && files.length > 0) {
        try {
          // Convert the selected image to base64
          const newImageBase64 = await convertFileToBase64(files[0]);
  
          // Add the desired prefix to the base64 value
          const finalImageBase64 = `data:image/jpeg;base64,${newImageBase64}`;
  
          // Update the state with the new image for 'image_1'
          setImagesObject((prevImages) => ({
            ...prevImages,
            [key]: finalImageBase64,
          }));
        } catch (error) {
          console.error("Error converting image to base64:", error);
        }
      }
    });
  
    // Click the file input programmatically to trigger file selection
    fileInput.click();
  };
  
  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = () => {
        reject(new Error("Failed to read the file."));
      };
      reader.readAsDataURL(file);
    });
  };
  
  useEffect(() => {
    if (cakeId) getSingleCake(cakeId);
    getLoggedUser();
  }, []);
  const AddCake = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Please wait...");
    if (
     
      !dec
    ) {
      toast.update(toastId, {
        render: "Please fill in the description.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }
    if (
    imagesObject.length==0
    ) {
      toast.update(toastId, {
        render: "Please add an image.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }
    if (
      !cakeName  ) {
      toast.update(toastId, {
        render: "Please enter the cake name.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }
    const phoneRegex = /^(\+\d{1,3})?\d{10}$/;

    if (!phoneRegex.test(phone)) {
      toast.update(toastId, {
        render: "Please enter a valid phone number.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });       return;
    }
    try {
      if (cakeId) {
        const filteredImagesObject = {};

        for (const key in imagesObject) {
          if (
            Object.prototype.hasOwnProperty.call(imagesObject, key) &&
            !imagesObject[key].startsWith("http") &&
            !imagesObject[key].startsWith("https://thehomecakesite.")
          ) {
            const base64Value = imagesObject[key];
            const modifiedBase64Value = base64Value.replace(
              /^data:image\/(jpeg|png|webp|avif);base64,/,
              ""
            );

            filteredImagesObject[key] = modifiedBase64Value;
          }
        }
        const data = {
          id: cakeId,
          name: cakeName,
          price: `${price}`,
          type: type,
          weight: weight,
          currency: currency,
          images: filteredImagesObject,
          whatsapp_number: `${wbNumber}`,
          country: country,
          region: region,
          special_instructions: instructions,
          ingredients: ingredients,
          description: dec,
        };
        const response = await axiosInstance.patch("/api/cake/edit", data,{
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }).catch(error => {
          console.error("Axios Error:", error);
          throw error; // rethrow the error to propagate it further
        });;

        if (response.status === 200) {
        
          toast.update(toastId, {
            render: "Cake Edit successfully",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });

          setCakeName("");
          setPrice("");
          setCountry("");
          setRegion("");
          setIngredients([]);
          setImagesObject([]);
          setType("");
          setCurrency("");
          setPhone("");
          setPhoneCode("");
          setWeight("");
          setInstructions("");
          setDec("");
          setWbNumber("");
          setSelectedCountry("");
          navigate("/dashboard/myCakes");
        } if (response?.status === 201) {
          toast.update(toastId, {
            render: response?.message,
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });}
      } else {
        const modifiedImagesObject = {};

        for (const key in imagesObject) {
          if (Object.prototype.hasOwnProperty.call(imagesObject, key)) {
            const base64Value = imagesObject[key];
            modifiedImagesObject[key] = base64Value.replace(
              /^data:image\/(jpeg|png|webp|avif);base64,/,
              ""
            );
          }
        }
        const data = {
        
          
          name: cakeName,
          price: `${price} `,
          type: type,
          weight: weight,
          images: modifiedImagesObject,
           currency: currency,
          country: country,
          region: region,
          special_instructions: instructions,
          ingredients: ingredients,
          description: dec,
          whatsapp_number: `${wbNumber}`,
        };
      
        const response = await axiosInstance.post("/api/cake/add", data,{
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        });
        console.log('response', response)
        if (response?.status === 200) {
      
          toast.update(toastId, {
            render: "Cake Added successfully",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
          setCakeName("");
          setCurrency("");
          setPhone("");
          setPhoneCode("");
          setPrice("");
          setCountry("");
          setRegion("");
          setIngredients([]);
          setImagesObject([]);
          setType("");
          setWeight("");
          setInstructions("");
          setDec("");
          setWbNumber("");
          setSelectedCountry("");
          navigate("/dashboard/myCakes");
        }
         if (response?.status === 201) {
            toast.update(toastId, {
              render: response?.data?.message,
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });}
        
        
      }
    } catch (error) {
      console.log("error", error);
      toast.update(toastId, {
        render: error?.message,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  const handleImageUpload = (event) => {
    const files = event.target.files;
    const imageFiles = Array.from(files);
    const allowedFormats = ["image/jpeg", "image/png"];
    const invalidFiles = imageFiles.filter(
      (file) => !allowedFormats.includes(file.type)
    );
    if (invalidFiles.length > 0) {
      toast.error("Only JPEG and PNG images are allowed");
      return;
    }
    if (Object.keys(imagesObject).length + imageFiles.length > 10) {
      toast.error("Maximum 10 images allowed");
      return;
    }
  
    const imagePromises = Array.from(files).map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          reject(new Error("Failed to read the file."));
        };
        reader.readAsDataURL(file);
      });
    });
  
    Promise.all(imagePromises)
      .then((results) => {
        const newImages = results.map((result, index) => ({
          [`image_${Object.keys(imagesObject).length + index + 1}`]: result,
        }));
        setImagesObject((prevImages) => {
          const emptyStringKey = Object.keys(prevImages).find(
            (key) => prevImages[key] === ""
          );
  
          if (emptyStringKey) {
            return {
              ...prevImages,
              [emptyStringKey]: results[0], // Update the empty string property
            };
          }
  
          return {
            ...prevImages,
            ...Object.assign({}, ...newImages),
          };
        });
      })
      .catch((error) => {
        // Handle the error if needed
      });
  };
  
  const removeImage = (key) => {
    const newImages = { ...imagesObject };
    delete newImages[key];
    if (cakeId) newImages[key] = "";
    setImagesObject(newImages);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <RegisterdLayout>
      <div className="userback">
        <Grid container padding="4%">
          <Grid
            item
            xs={12}
            sm={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            {" "}
            <Typography variant="h6">
              {" "}
              For a better experience, please upload landscape cake images.{" "}
            </Typography>
            <Grid
              sx={{
                height: "fit-content",
                width: "100%",
                maxWidth: "1240px",
                background: "white",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {Object.entries(imagesObject)
                .filter(([key, value]) => value !== "")
                .map(([key, value], index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {key === "image_1" ? (
                      <>
                       <ReplayCircleFilledIcon
                          color="primary"
                          sx={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            cursor: "pointer",
                          }}
                          onClick={() => replaceImage("image_1")}
                        />
                       
                        <img
                          src={value}
                          alt={`Image ${index + 1}`}
                          style={{
                            maxWidth: "150px",
                            maxHeight: "100px",
                            margin: "10px",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <DeleteIcon
                          color="error"
                          sx={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            cursor: "pointer",
                          }}
                          onClick={() => removeImage(key)}
                        />

                        <img
                          src={value}
                          alt={`Image ${index + 1}`}
                          style={{
                            maxWidth: "150px",
                            maxHeight: "100px",
                            margin: "10px",
                          }}
                        />
                      </>
                    )}
                  </div>
                ))}
            </Grid>
            <input
              type="file"
              style={{ display: "none" }}
              ref={fileInputRef}
              accept="image/png, image/jpeg, image/jpg"
              multiple
              onChange={handleImageUpload}
            />
            <Button
              variant="contained"
              sx={{ maxWidth: "200px" }}
              onClick={handleUploadButtonClick}
            >
              {" "}
              Choose Images
            </Button>
            <Grid
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                width: "100%",
                maxWidth: "1240px",
              }}
            >
              <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
                <TextField
                  sx={{ width: "100%" }}
                  InputProps={{
                    sx: {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "15px",
                      borderColor: "#EE548A",

                      fontSize: "16px",
                    },
                  }}
                  placeholder="Cake Name"
                  variant="outlined"
                  value={cakeName}
                  onChange={(e) => {
                    const enteredName = e.target.value;
                    setCakeName(enteredName);
                    setNameError(!/^[a-zA-Z].*$/.test(enteredName));
                  }}
                />
              </div>

              <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
                <FormControl sx={{ width: "100%", maxWidth: "440px" }}>
                  <Select
                    value={selectedCountry ? selectedCountry : "select"}
                    onChange={(e) => {
                      const selectedCountry = e.target.value;
                      setSelectedCountry(selectedCountry);
                      setCountry(selectedCountry);
                      setAvailableRegions(regions[selectedCountry]);
                      const currencySymbol =
                        countries[selectedCountry]?.currencySymbol || "";
                      setCurrency(currencySymbol);
                    }}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "15px",
                      borderColor: "#EE548A",
                      fontSize: "16px",
                      margin: "0% 2%",
                      color: selectedCountry ? "" : "#BCBCBC !important",
                    }}
                  >
                    <MenuItem value="select" disabled>
                      Country
                    </MenuItem>
                    {Object.keys(countries).map((country) => (
                      <MenuItem key={country} value={country}>
                        {country}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
                <FormControl sx={{ width: "100%", maxWidth: "440px" }}>
                  <Select
                    value={region ? region : "select"}
                    onChange={(e) => {
                      setRegion(e.target.value);
                    }}
                    sx={{
                      backgroundColor: "#FFFFFF",
                      borderRadius: "15px",
                      borderColor: "#EE548A",
                      fontSize: "16px",
                      margin: "0% 2%",
                      color: region ? "" : "#BCBCBC !important",
                    }}
                  >
                    <MenuItem value="select" disabled>
                      Region
                    </MenuItem>
                    {availableRegions.map((region) => (
                      <MenuItem key={region} value={region}>
                        {region}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                width: "100%",
                maxWidth: "1240px",
              }}
            >
              {" "}
              <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
                <TextField
                  sx={{ width: "100%" }}
                  InputProps={{
                    sx: {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "15px",
                      borderColor: "#EE548A",
                      fontSize: "16px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">{currency}</InputAdornment>
                    ),
                  }}
                  placeholder="Price"
                  variant="outlined"
                  type="number"
                  value={price}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                />
              </div>{" "}
              <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
                <TextField
                  sx={{ width: "100%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CountrySelector
                          selectedCountry={flag}
                          countries={countryCode}
                          onSelect={({ dialCode, iso2 }) => {
                            setWbNumber(`+${dialCode}`);
                            setPhoneCode(dialCode);
                            setFlag(iso2);
                          }}
                        />
                      </InputAdornment>
                    ),
                    sx: {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "15px",
                      borderColor: "#EE548A",
                      fontSize: "16px",
                      margin: "0% 2%",
                    },
                  }}
                  placeholder="Phone Number"
                  variant="outlined"
                  value={wbNumber}
                  onChange={(e) => {
                    setWbNumber(e.target.value);
                  }}
                />
              </div>
              <div style={{ width: "100%", maxWidth: "440px" }}>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "8px",
                    width: "100%",
                    maxWidth: "640px",
                  
                  }}
                >
                  <TextField
                    sx={{ width: "100%" }}
                    InputProps={{
                      sx: {
                        backgroundColor: "#FFFFFF",
                        borderRadius: "15px",
                        borderColor: "#EE548A",
                        fontSize: "16px",
                        height: "57px", 
                      },
                    }}
                    placeholder="Type"
                    variant="outlined"
                    value={type}
                    onChange={(e) => {
                      setType(e.target.value);
                      setPhoneError(!/^\+[\d\s-]+$/.test(e.target.value));
                    }}
                  />
                  <TextField
                    sx={{ width: "100%" }}
                    InputProps={{
                      sx: {
                        backgroundColor: "#FFFFFF",
                        borderRadius: "15px",
                        borderColor: "#EE548A",
                        fontSize: "16px",
                        height: "57px", 
                      },
                      endAdornment: (
                        <InputAdornment position="end">
                          {selectedUnit}
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Weight"
                    type="number"
                    variant="outlined"
                    value={weight}
                    onChange={(e) => {
                      setWeight(e.target.value);
                    }}
                  />
                </Grid>
              </div>
            </Grid>
            <Grid
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
                width: "100%",
                maxWidth: "1240px",
              }}
            >
              <div style={{ width: "100%", maxWidth: "840px" }}>
                <TextField
                  multiline
                  minRows={4}
                  maxRows={4}
                  sx={{ width: "100%" }}
                  InputProps={{
                    sx: {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "15px",
                      borderColor: "#EE548A",
                      fontSize: "16px",
                      width: "100%",
                    },
                  }}
                  placeholder="Special Instructions"
                  variant="outlined"
                  value={instructions}
                  onChange={(e) => {
                    setInstructions(e.target.value);
                  }}
                />
              </div>
              <div style={{ width: "100%", maxWidth: "840px" }}>
                <Autocomplete
                  multiple
                  id="tags-filled"
                  options={[]}
                  value={ingredients}
                  onChange={(event, newIngredients) => {
                    setIngredients(newIngredients);
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "15px",
                      borderColor: "#EE548A",
                      fontSize: "16px",
                      backgroundColor: "#FFFFFF",
                      overflowY: "auto", // Add this line to enable vertical scrolling
                      height: "160px", // Adjust the maximum height as needed
                      
                    },
                  }}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      sx={{ width: "100%" }}
                      maxRows={4}
                      InputProps={{
                        sx: {
                          backgroundColor: "#FFFFFF",
                          borderRadius: "15px",
                          borderColor: "#EE548A",
                          fontSize: "16px",
                          width: "100%",
                        },
                      }}
                      {...params}
                      variant="outlined"
                      placeholder="Pressing Enter adds  a new ingredient"
                    />
                  )}
                />
              </div>
              <div style={{ width: "100%", maxWidth: "840px" }}>
                <TextField
                  multiline
                  minRows={4}
                  maxRows={4}
                  sx={{ width: "100%" }}
                  InputProps={{
                    sx: {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "15px",
                      borderColor: "#EE548A",
                      fontSize: "16px",
                      width: "100%",
                    },
                  }}
                  placeholder="Description"
                  variant="outlined"
                  value={dec}
                  onChange={(e) => {
                    setDec(e.target.value);
                  }}
                />
              </div>
            </Grid>
            <Button
              variant="contained"
              sx={{ maxWidth: "200px" }}
              onClick={AddCake}
            >
              {" "}
              {cakeId ? "Update Cake" : "Add Cake"}
            </Button>
          </Grid>
        </Grid>
      </div>
    </RegisterdLayout>
  );
}
