import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import  Delete  from "../../assets/images/delete.svg";

export default function DeleteCard({cancel,deletecake}) {
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        gap: "12px",
        padding:'18px'
      }}
    >
      <Typography variant="h2"> Delete Cake Confirmation</Typography>
      <img loading="lazy" src={Delete} alt="delete" />
      <Typography variant="h3">
        {" "}
        Are you sure you want to delete this cake?{" "}
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: "8px",
        }}
      >
        <Button variant="outlined" onClick={cancel}>
          {" "}
          Cancel
        </Button>
        <Button variant="contained"   onClick={deletecake}>
          Delete
        </Button>
      </div>
    </Grid>
  );
}
