import { Button, Card, Chip, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
export default function ProductSmall({
  image,
  chip,
  title,
  para,
  deleteopen,
  cakeId,
}) {
  const navigate = useNavigate();
  const handleEditClick = () => {
    navigate(`/dashboard/add-cake?id=${cakeId}`);
  };
  let chipColor = "default";
  if (chip === "APPROVED") {
    chipColor = "success";
  } else if (chip === "PENDING") {
    chipColor = "warning";
  } else if (chip === "REJECTED") {
    chipColor = "error";
  }
  return (
    <Card sx={{ minWidth: "300px", maxWidth: "300px", margin: "10px" }}>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "6px",
          padding: "10px",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWwidth: "280px",
            height: "155px",
            overflow: "hidden",
          }}
        >
          <img
            src={image}
            id={cakeId}
            alt="product"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "45px 45px 0px 0px",
            }}
          />
        </div>
        <Grid
          sx={{
            mb: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "6px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: "280px",
            }}
          >
            {title}
          </Typography>
          <Chip
            label={chip}
            variant="outlined"
            color={chipColor}
            sx={{ maxWidth: "100px" }}
          />{" "}
          <Typography
            variant="body3"
            style={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              height: "64px",
            }}
          >
            {para}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "4px",
            padding: "4px",
          }}
        >
          {chip === "REJECTED" ? (
            ""
          ) : (
            <Button variant="outlined" onClick={handleEditClick}>
              {" "}
              Edit{" "}
            </Button>
          )}
          <Button
            variant="contained"
            sx={{ backgroundColor: "#EC5B5B" }}
            onClick={deleteopen}
          >
            {" "}
            Delete
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
