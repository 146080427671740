import { Button, TextField, IconButton } from "@mui/material";
import React, { useState } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import axiosInstance from "../../api/axios";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AdminLayout from "../../components/layouts/AdminLayout";

export default function ChangePass() {
  const navigate = useNavigate();
  const [oldPassword, setOldPassword] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [oldPasswordError, setOldPasswordError] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);

  const reset = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Please wait...");
    if (passwordError ) {
      toast.update(toastId, {
        render: "Password must be 8-12 characters long and include at least one capital letter, one number, and one special character",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return;
    }
    try {
      const response = await axiosInstance.post("/api/auth/changePassword", {
        current_password: oldPassword,
        new_password: confirmPassword,
      });
      if (response.status === 200) {
        toast.update(toastId, {
          render: "Password Set  successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });

        navigate("/dashboard");
      } else if (response.status === 201) {
       
          toast.update(toastId, {
            render: response.data.message,

            type: "warning",
            isLoading: false,
            autoClose: 3000,
          });
        
      } else {
      }
    } catch (error) {
      console.log("error", error);
      toast.update(toastId, {
        render: error?.response?.data,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <AdminLayout>
      <div className="userback">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            padding: "4%",
          }}
        >
          <TextField
            sx={{ height: "57px", width: "100%", maxWidth: "440px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon sx={{ color: "black" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowOldPassword(!showOldPassword)}
                    aria-label="toggle password visibility"
                  >
                    {showOldPassword ? (
                      <VisibilityIcon sx={{ color: "black" }} />
                    ) : (
                      <VisibilityOffIcon sx={{ color: "black" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                backgroundColor: "#FFFFFF",
                borderRadius: "15px",
                borderColor: "#EE548A",
                fontSize: "16px",
                height: "57px",
              },
            }}
            placeholder="Current Password"
            variant="outlined"
            type={showOldPassword ? "text" : "password"}
            value={oldPassword}
            onChange={(e) => {
              const enteredPassword = e.target.value;
              setOldPassword(enteredPassword);
              setOldPasswordError(
                !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,12}$/.test(
                  enteredPassword
                )
              );
            }}
            error={oldPasswordError}
            helperText={oldPasswordError && "Invalid password"}
          />
          <TextField
            sx={{ height: "57px", width: "100%", maxWidth: "440px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon sx={{ color: "black" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label="toggle password visibility"
                  >
                    {showPassword ? (
                      <VisibilityIcon sx={{ color: "black" }} />
                    ) : (
                      <VisibilityOffIcon sx={{ color: "black" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                backgroundColor: "#FFFFFF",
                borderRadius: "15px",
                borderColor: "#EE548A",
                fontSize: "16px",
                height: "57px",
              },
            }}
            placeholder=" New Password"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => {
              const enteredPassword = e.target.value;
              setPassword(enteredPassword);
              setPasswordError(
                !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,12}$/.test(
                  enteredPassword
                )
              );
            }}
            error={passwordError}
            helperText={passwordError && "Invalid password"}
          />
          <TextField
            sx={{ height: "57px", width: "100%", maxWidth: "440px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockIcon sx={{ color: "black" }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    aria-label="toggle confirm password visibility"
                  >
                    {showConfirmPassword ? (
                      <VisibilityIcon sx={{ color: "black" }} />
                    ) : (
                      <VisibilityOffIcon sx={{ color: "black" }} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
              sx: {
                backgroundColor: "#FFFFFF",
                borderRadius: "15px",
                borderColor: "#EE548A",
                fontSize: "16px",
              },
            }}
            placeholder="Confirm Password"
            variant="outlined"
            type={showConfirmPassword ? "text" : "password"}
            value={confirmPassword}
            onChange={(e) => {
              const enteredConfirmPassword = e.target.value;
              setConfirmPassword(enteredConfirmPassword);
              setConfirmPasswordError(enteredConfirmPassword !== password);
            }}
            error={confirmPasswordError}
            helperText={confirmPasswordError && "Passwords do not match"}
          />
          <Button
            variant="contained"
            sx={{ maxWidth: "250px" }}
            onClick={reset}
            disabled={
              confirmPasswordError ||
              passwordError ||
              oldPasswordError ||
              !oldPassword
            }
          >
            Update
          </Button>
        </div>
      </div>
    </AdminLayout>
  );
}
