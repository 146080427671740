import React from "react";
import UnRegisterdLayout from "../components/layouts/UnRegisterdLayout";
import { Typography, Grid } from "@mui/material";
import { Container } from "@mui/system";
import Cake from "../assets/images/cake.jpg";
import Banner from "../components/cards/Banner";
import BannerImg from "../assets/images/banner.png";
import BannerTest from "../assets/images/test.jpg";
import CakeShop from "../assets/images/shop.jpg";

export default function About() {
  return (
    <UnRegisterdLayout>
      <Container maxWidth="xl" sx={{ mt: "4vh", mb: "4vh", padding: "2px" }}>
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "center",
            alignItems: "center",
            padding: "2%",
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "18px",
              marginBottom: "10px",
            }}
          >
            <Typography variant="h1"> About Us</Typography>
            <Typography variant="body1">
            Welcome to TheHomeCakes.com, your ultimate destination for discovering and indulging in delightful confections that seamlessly blend the charm of homemade treats with unmatched taste. As a buyer on our platform, you're invited to explore a world of exquisite cakes crafted by passionate sellers, each offering a unique touch to elevate your sweet experiences.
            </Typography>
            <Typography variant="body1">
            At TheHomeCakes.com, we celebrate the magic of homemade goodness, ensuring that every cake you choose is a testament to the seller's dedication and expertise. Revel in the joy of savoring premium ingredients and attention to detail, promising a delectable taste that not only satisfies your sweet cravings but also creates memorable moments.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "18px",
              marginBottom: "10px",
            }}
          >
            <img
              src={Cake}
              alt="cake"
              style={{ widthL: "100%", padding: "4%" }}
            />
          </Grid>
        </Grid>
        <Grid
          sx={{
            background: "#A14364",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            flexDirection: "row",
            padding: "2%",
          }}
        >
          <div>
            <Typography variant="h4" sx={{ color: "white" }}>
              100+<br></br> Cakes
            </Typography>
          </div>
          <div>
            <Typography variant="h4" sx={{ color: "white" }}>
              50+<br></br>  Users
            </Typography>
          </div>
          <div>
            <Typography variant="h4" sx={{ color: "white" }}>
              10+<br></br> Countrys
            </Typography>
          </div>
        </Grid>
    
        <Typography variant="body1" mb={4}mt={4} textAlign={'center'}>
        Embark on a sweet journey at TheHomeCakes.com, where the pleasures of homemade goodness meet your sweetest cravings. Indulge in the perfect slice – because every moment is sweeter with TheHomeCakes touch!
        </Typography>

        <Grid sx={{ mt: "8vh", mb: "8vh" }}>
          <Banner
            img={CakeShop}
            title="For Cake Sellers"
            para="Home Cakes is the canvas for your creativity. As a passionate cake artist, you have the opportunity to showcase your culinary masterpieces to a community of enthusiasts who truly value the craftsmanship behind each confection. "
          />
        </Grid>
        <Grid sx={{ mt: "8vh", mb: "8vh" }}>
          <Banner
            directionProp="rtl"
            img={BannerTest}
            title="For Cake Enthusiasts"
            para="Embark on a delightful journey through a curated collection of cakes, each telling a unique story through flavors, designs, and personal touches. Home Cakes is your passport to a world of sweet wonders, where you can explore classic favorites, trendy treats, and hidden gems crafted by passionate bakers."
          />
        </Grid>
      </Container>
    </UnRegisterdLayout>
  );
}
