import React, { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import MobileNav from "./MobileNav";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import DashboardNav from "./DashboardNav";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axiosInstance from "../../api/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logo.png";
export const NavBar = ({ userdash }) => {
  const { user } = useContext(AuthContext);
  const storedUserString = localStorage.getItem("user");
  const storedUser = JSON.parse(storedUserString);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [userDp, setUserDp] = useState("");
  const [name, setName] = useState("");
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  const handleDash = () => {
   
    navigate((storedUser?.type === "SUPER_ADMIN" || storedUser?.type ==="MAINTAINER")?"/admin-dashboard":"/dashboard");
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    getLoggedUser();
  }, []);
  const getLoggedUser = async () => {
    try {
      axiosInstance
        .get("/api/user/current", {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          const user = response?.data?.data;
          setName(user?.user_name);
          setUserDp(user?.image || "");
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
      if (!err?.response) {
        toast.error("User not fount");
        return;
      }
    }
  };

  const firstLetter = name ? name.charAt(0).toUpperCase() : "";
  return (
    <Container maxWidth="xl">
      <AppBar
        position="static"
        color="default"
        style={{
          backgroundColor: "white",
          boxShadow: "none",
          marginBottom: "18px",
        }}
      >
        <Grid
          container
          className="flexrow"
          sx={{ display: { xs: "none", md: "flex" } }}
        >
          <Grid item lg={6} sm={4} className="flexrow">
            <Link style={{ textDecoration: "none !important" }} to="/">
              {" "}
              {/* <Typography variant="h4">The<span style={{color:'#EE548A'}}>Home</span>Cakes</Typography>{" "} */}
              <img style={{width:'100%',maxWidth:'200px'}} src={Logo} alt="logo"/>
            </Link>
          </Grid>
          <Grid item lg={6} sm={8} className="flexrow">
            <Typography variant="body2">
              <Link to="/">Home</Link>
            </Typography>

            <Link to="/About">
              <Typography variant="body2">About </Typography>
            </Link>
            <Link to="/Contact">
              <Typography variant="body2">Contact </Typography>
            </Link>
            <Link to="/Cakes">
              <Typography variant="body2">Cakes </Typography>
            </Link>

            <Grid>
              {user ? (
                <div>
                  <Avatar
                    src={userDp}
                    alt={firstLetter}
                    onClick={handleClick}
                    sx={{ width: 50, height: 50,border:'1px solid #EE548A' }}
                  >
                    {!userDp && firstLetter}
                  </Avatar>

                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                   
                      <MenuItem onClick={handleDash}>Dashboard</MenuItem>
                   
                    <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
                  </Menu>
                </div>
              ) : (
                <Link to={"/login"}>
                  <Button variant="contained">Register Now</Button>{" "}
                </Link>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: { xs: "flex", md: "none" },
            justifyContent: userdash ? "space-between" : "flex-end",
          }}
        >
          {userdash && <DashboardNav />}
          <MobileNav />
        </Grid>
      </AppBar>
    </Container>
  );
};
