import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import { Grid, Typography } from "@mui/material";
import SearchBar from "../components/core/SearchBar";
import ProductCard from "../components/cards/ProductCard";
import axiosInstance from "../api/axios";
import UnRegisterdLayout from "../components/layouts/UnRegisterdLayout";
import axios from "axios";
import NoResults from "../assets/images/not.svg";
import Spinner from "../assets/images/Spinner.gif";

import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
export default function Cakes() {
  const [cakes, setCakes] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [availableRegions, setAvailableRegions] = useState([]);
  const [country, setCountry] = useState("");
  const [region, setRegion] = useState("");
  const [type, setType] = useState("");
  const [allUsers, setAllUsers] = useState([]);
  const [userName, setUserName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [totall, setTotall] = useState("");
    const [loading, setLoading] = useState(true);

  const countries = {
    "Sri Lanka": { currencySymbol: "LKR" },
    "United Kingdom": { currencySymbol: "GBP" },
    Canada: { currencySymbol: "CAD" },
  };

  const regions = {
    "Sri Lanka": [
      "Colombo",
      "Gampaha",
      "Kalutara",
      "Kandy",
      "Matale",
      "Nuwara Eliya",
      "Galle",
      "Matara",
      "Hambantota",
      "Jaffna",
      "Kilinochchi",
      "Mannar",
      "Vavuniya",
      "Mullaitivu",
      "Batticaloa",
      "Ampara",
      "Trincomalee",
      "Kurunegala",
      "Puttalam",
      "Anuradhapura",
      "Polonnaruwa",
      "Badulla",
      "Monaragala",
      "Ratnapura",
      "Kegalle",
    ],
    "United Kingdom": [
      "London",
      "Birmingham",
      "Manchester",
      "Liverpool",
      "Leeds",
      "Newcastle",
      "Sheffield",
      "Bristol",
      "Oxford",
      "Cambridge",
    ],
    Canada: ["Ontario", "Quebec", "British Columbia", "Alberta", "Manitoba"],
  };
  useEffect(() => {
    getUsers();
    getCakes();
  }, [country, region, type, userName, currentPage]);
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = cakes?.items?.slice(indexOfFirstItem, indexOfLastItem);
  // useEffect(() => {

  //   axios
  //   .get("https://geolocation-db.com/json/")
  //   .then((response) => {
  //     const data = response.data;

  //     setUserCountry(data?.country_name);
  //   })
  //   .catch((error) => {
  //     console.error("Error fetching IP:", error);
  //   });
  // }, [userCountry]);
  const getUsers = async () => {
    try {
      axiosInstance
        .post("/api/user/search", {
          user_name: "",
        })
        .then((response) => {
          const user = response?.data?.data;
          setAllUsers(user || []);
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
    }
  };
  const getCakes = async () => {
    try {
      const response = await axiosInstance.post("/api/cake/find", {
        limit: itemsPerPage,
        page: currentPage,
        search_key: {
          country: country,
          region: region,
          price: "",
          type: type,
          user_id: userName,
        },
      });

      const cakesData = response?.data?.data;

      setCakes(cakesData?.items || []);
        setLoading(false);
      setTotall(cakesData?.meta?.total);
    } catch (err) {
      console.log("error", err);
        setLoading(false);
    }
  };
  const clearall = () => {
    setCountry("");
    setSelectedCountry("");
    setRegion("");
    setType("");
    setUserName("");
  };
  return (
    <UnRegisterdLayout>
      <Container maxWidth="xl">
        <Grid sx={{ mt: "8vh", mb: "8vh" }}>
          <SearchBar
            search={() => getCakes()}
            regionchange={(e) => {
              setRegion(e.target.value);
            }}
            typechange={(e) => {
              setType(e.target.value);
            }}
            type={type}
            selectedCountry={selectedCountry}
            region={region}
            countries={countries}
            availableRegions={availableRegions}
            countryonchange={(e) => {
              const selectedCountry = e.target.value;
              setSelectedCountry(selectedCountry);
              setCountry(selectedCountry);
              setAvailableRegions(regions[selectedCountry] || []);
            }}
            userChange={(e) => {
              setUserName(e.target.value);
            }}
            user={userName}
            users={allUsers}
            clear={clearall}
          />
        </Grid>
      </Container>

      <Grid className="backimg">
        <Container maxWidth="xl">
          <Grid
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
          <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",

                color: "black",
                display: cakes?.length > 0 ? "none" : "flex",
              }}
            >
                {loading ? (
                <img
                  src={Spinner}
                  alt="no results"
                  style={{
                    borderRadius: 8,
                    objectFit: "cover",
                    width: "100%",
                    maxWidth: "350px",
                    height: "auto",
                  }}
                />
              ) : (
                <img
                  src={NoResults}
                  alt="no results"
                  style={{
                    borderRadius: 8,
                    objectFit: "cover",
                    width: "100%",
                    maxWidth: "350px",
                    height: "auto",
                  }}
                />
              )}

              <Typography variant="body1" mt={4}>
                {loading ? "Loading..." : "Sorry! No result found"}
              </Typography>
            </Grid>

            {cakes?.map((product, index) => (
              <ProductCard
                key={index}
                image={product?.images?.image_1_thumb}
                title={product?.name}
                para={product?.description}
                price={product?.price}
                cakeId={product?.id}
                usd={product?.currency}
              />
            ))}
          </Grid>
          <Stack spacing={2} sx={{margin:'0 auto', padding:'18px'}}>
          <Pagination
            count={Math.ceil(totall / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
          </Stack>
        </Container>
      </Grid>
    </UnRegisterdLayout>
  );
}
