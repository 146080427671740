import { useState } from "react";
import React, { useContext } from "react";
import Close from "../../assets/icons/close.svg";
import { Link } from "react-router-dom";
import {
  Grid,
  Button,
  Drawer,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";

function DashboardNav(props) {
  const [open, setOpen] = useState(false);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const storedUserString = localStorage.getItem("user");
  const storedUser = JSON.parse(storedUserString);
  const toggleDrawer = () => {
    setOpen(!open);
  };
  const drawerWidth = "50%";
  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  const link5 =
    storedUser?.type === "SUPER_ADMIN" ? "/admin-dashboard/admins" : null;
  const text5 = storedUser?.type === "SUPER_ADMIN" ? "Admins" : null;
  const link3 =
    storedUser?.type === "SELLER_USER" ? "/dashboard/add-cake" : null;
  const text3 = storedUser?.type === "SELLER_USER" ? "Add Cakes" : null;
  const link4 =
    storedUser?.type === "SELLER_USER" ? "/dashboard/myCakes" : null;
  const text4 = storedUser?.type === "SELLER_USER" ? "My Cakes" : null;
  return (
    <>
      <Button onClick={toggleDrawer}>
        {" "}
        <Button variant="contained">User Menu</Button>
      </Button>
      <Drawer
        anchor="left"
        open={open}
        onClose={toggleDrawer}
        PaperProps={{
          style: {
            width: drawerWidth,
          },
        }}
      >
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button onClick={toggleDrawer}>
              <img loading="lazy" src={Close} alt="Menu" />
            </Button>
          </Grid>

          <Grid>
            <List>
              <ListItem>
                <Link
                  to={
                    storedUser?.type === "SUPER_ADMIN" ||
                    storedUser?.type === "MAINTAINER"
                      ? "/admin-dashboard"
                      : storedUser?.type === "SELLER_USER" ||
                        storedUser?.type === "BUYER_USER"
                      ? "/dashboard"
                      : "/login"
                  }
                >
                  <Typography variant="body2"> Dashboard</Typography>
                </Link>
              </ListItem>
              <ListItem>
                <Link
                  to={
                    storedUser?.type === "SUPER_ADMIN" ||
                    storedUser?.type === "MAINTAINER"
                      ? "/admin-dashboard/profile"
                      : storedUser?.type === "SELLER_USER" ||
                        storedUser?.type === "BUYER_USER"
                      ? "/dashboard/profile"
                      : "/login"
                  }
                >
                  <Typography variant="body2"> My Profile</Typography>
                </Link>
              </ListItem>
              {storedUser?.type === "SELLER_USER" ||
              storedUser?.type === "BUYER_USER" ? (
                <>
                  {" "}
                  {text3 && (
                    <ListItem>
                      <Link to={link3}>
                        <Typography variant="body2"> {text3}</Typography>
                      </Link>
                    </ListItem>
                  )}
                  {text4 && (
                    <ListItem>
                      <Link to={link4}>
                        <Typography variant="body2">{text4}</Typography>
                      </Link>
                    </ListItem>
                  )}
                  <ListItem>
                    <Link to="/dashboard/profile">
                      <Typography variant="body2"> Profile</Typography>
                    </Link>
                  </ListItem>
                </>
              ) : (
                ""
              )}

              {storedUser?.type === "SUPER_ADMIN" ||
              storedUser?.type === "MAINTAINER" ? (
                <>
                  {" "}
                  <ListItem>
                    <Link to="/admin-dashboard/users">
                      <Typography variant="body2"> Users</Typography>
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/admin-dashboard/cakes">
                      <Typography variant="body2"> Cakes</Typography>
                    </Link>
                  </ListItem>
                  {text5 && (
                    <ListItem>
                      <Link to={link5}>
                        <Typography variant="body2"> {text5}</Typography>
                      </Link>
                    </ListItem>
                  )}
                  <ListItem>
                    <Link to="/admin-dashboard/requests">
                      <Typography variant="body2"> Requests</Typography>
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link to="/admin-dashboard/profile">
                      <Typography variant="body2"> Profile</Typography>
                    </Link>
                  </ListItem>{" "}
                </>
              ) : (
                ""
              )}
            </List>
            <Button variant="contained" onClick={handleLogout}>
              {" "}
              Logout{" "}
            </Button>
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
}

export default DashboardNav;
