import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
export default function Footer() {
    const storedUserString = localStorage.getItem("user");
      const storedUser = JSON.parse(storedUserString);

  return (
    <Grid sx={{ backgroundColor: "#A14364" }}>
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-between",
            alignItems: "center",
            color: "#FFFFFF",
            padding: "2%",
          }}
        >
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Typography variant="h4">TheHomeCakes</Typography>
            <Typography variant="body3" sx={{textAlign:'center',width:'100%'}}>
            "Welcome to TheHomeCakes.com – your go-to for irresistible homemade treats. As a seller, showcase and sell your crafted masterpieces to a broad community of dessert enthusiasts."        </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Typography variant="h3">Pages</Typography>
            <Typography variant="body3">
              <Link to="/">Home</Link>
            </Typography>

            <Link to="/About">
              <Typography variant="body3">About </Typography>
            </Link>
            <Link to="/Contact">
              <Typography variant="body3">Contact </Typography>
            </Link>
            <Link to="/Cakes">
              <Typography variant="body3">Cakes </Typography>
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
             
            }}
          >
            <Typography variant="h3">Quick Links</Typography>
           

            <Link to="/privacy-policy">
              <Typography variant="body3">Privacy Policy </Typography>
            </Link>
            <Link to="/terms-conditions">
              <Typography variant="body3">Terms & Condtions </Typography>
            </Link>
           
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "8px",
            }}
          >
            <Typography variant="h3">Social Media</Typography>
            <div style={{
               display: "flex",
               flexDirection: "row",
               justifyContent: "center",
               alignItems: "center",
               gap: "8px",
            }}>
            <FacebookIcon />
            <TwitterIcon />
            <InstagramIcon />
            </div>
       
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}
