import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/layouts/AdminLayout";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import axiosInstance from "../../api/axios";
import EmailIcon from "@mui/icons-material/Email";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import {
  Dialog,
  FormControl,
  Avatar,
  Select,
  MenuItem,
  IconButton,
  Button,
  TextField,
  Grid,
} from "@mui/material";
import Box from "@mui/material/Box";
import AreYouSure from "../../components/cards/AreYouSure";
import Are from "../../assets/icons/you.svg";
import { toast } from "react-toastify";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
export default function Admins() {
  const [users, setUsers] = useState([]);
  const [viewOpen, setViewOpen] = useState(false);
  const [adminOpen, setAdminOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [userId, setUserId] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [loggedInUserCountry, setLoggedInUserCountry] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [country, setCountry] = useState("");
  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };
  const countries = {
    "United Kingdom": { currencySymbol: "GBP" },
    "Sri Lanka": { currencySymbol: "LKR" },
    Canada: { currencySymbol: "CAD" },
  };
  const handleViewOpen = (id, userStatus) => {
    setUserStatus(userStatus);
    setUserId(id);
    setViewOpen(true);
  };
  const HandleAdminOpen = () => {
    setAdminOpen(true);
  };
  const AdminClose = () => {
    setAdminOpen(false);
  };
  const handleViewClose = () => {
    setViewOpen(false);
  };
  useEffect(() => {
    getLoggedUser();
    getUsers();
  }, [loggedInUserCountry, users]);
  function toDate(str) {
    let test = new Date(str.replace("T", " ").slice(0, -1));

    return test;
  }
  const handleCreateUser = async () => {
    if (!name) {
      setNameError(true);
      return;
    }
    if (!email) {
      setEmailError(true);
      return;
    }
    if (!country) {
      toast.error("Please select your country");
      return;
    }

    if (!password) {
      toast.error("Please enter Password");
      return;
    }

    const toastId = toast.loading("Please wait...");
    try {
      const userData = {
        user_name: name,
        email: email,
        password: password,
        country: country,
      };

      const response = await axiosInstance.post(
        `/api/user/addMaintainer`,
        userData
      );

      if (response.data) {
        if (response.status == "200") {
          toast.update(toastId, {
            render: "Account created successfully",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
          AdminClose();
          setName("");
          setEmail("");
          setPassword("");
        } else {
          toast.update(toastId, {
            render: response?.data?.message,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        }
      }
    } catch (error) {
      console.log("error", error.response?.data?.message);
      console.error(error);
      toast.update(toastId, {
        render: error.response?.data,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  const getLoggedUser = async () => {
    try {
      axiosInstance
        .get("/api/user/current", {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          const user = response?.data?.data;
          setLoggedInUserCountry(user?.country);
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
      if (!err?.response) {
        toast.error("User not fount");
        return;
      }
    }
  };
  const getUsers = async () => {
    try {
      axiosInstance
        .get("/api/user/getAllAdmins")
        .then((response) => {
          const allUsers = response?.data?.data || [];

          // Filter users based on the logged-in user's country
          const filteredUsers = allUsers.filter(
            (user) => user?.user_type === "MAINTAINER"
          );
          setUsers(filteredUsers);
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
    }
  };
  const changeFlow = async (disabled) => {
    const toastId = toast.loading("Please wait...");

    try {
      const response = await axiosInstance.patch(
        disabled ? "/api/user/enable" : "/api/user/disable",
        {
          id: userId,
        }
      );
      if (response.status === 200) {
        toast.update(toastId, {
          render: "User enable/disable successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        handleViewClose();
        getUsers();
      }
    } catch (err) {
      console.log("error", err);
      toast.update(toastId, {
        render: err?.response?.data,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return err;
    }
  };
  const userColumns = [
    { field: "oder", headerName: "ID", width: 50 },
    {
      field: "image",
      headerName: "Profile",
      width: 50,
      editable: false,
      renderCell: (params) => {
        return (
          <Avatar
            key={params?.row.id}
            alt={params?.row.name.charAt(0)}
            src={params?.row.image}
            style={{ width: 35, height: 35, textTransform: "capitalize" }}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 100,
      editable: false,
    },
    {
      field: "country",
      headerName: "Country",
      width: 100,
      editable: false,
    },

    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: false,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      type: "number",
      width: 200,
      editable: false,
    },

    {
      field: "is_active",
      headerName: "Active",
      type: "boolean",
      width: 80,
      editable: false,
    },
    {
      field: "created_at",
      headerName: "Created Date & Time",
      type: "dateTime",

      width: 200,
      editable: false,
    },
    {
      field: "user_type",
      headerName: "Type",

      width: 80,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {params?.row?.is_disabled ? (
              <Chip
                label="Activate"
                variant="filled"
                onClick={() =>
                  handleViewOpen(params?.row?.id, params?.row?.is_disabled)
                }
                color="success"
              />
            ) : (
              <Chip
                label="Suspend"
                variant="filled"
                onClick={() =>
                  handleViewOpen(params?.row?.id, params?.row?.is_disabled)
                }
                color="error"
              />
            )}
          </Box>
        );
      },
    },
  ];
  const userRows = users?.map((User, index) => ({
    id: User.id,
    oder: index + 1,
    image: User.image,
    name: User.user_name,
    email: User.email,
    phone_number: User.phone_number,
    post_code: User.post_code,
    region: User.region,
    is_active: User.is_active,
    country: User.country,
    user_type: User.user_type,
    is_disabled: User.is_disabled,
    created_at: toDate(User.created_at),
  }));
  return (
    <AdminLayout>
  <Grid> 
  <Button
        variant="contained"
        onClick={HandleAdminOpen}
        sx={{ maxWidth: "250px", textAlign: "center" }}
      >
        Add New Admin
      </Button>
      <DataGrid
      sx={{margin:'10px'}}
        rows={userRows}
        style={{ fontSize: "14px" }}
        columns={userColumns}
        pageSize={[10]}
        rowsPerPageOptions={[10]}
        slots={{ toolbar: GridToolbar }}
        experimentalFeatures={{ newEditingApi: true }}
      /> </Grid>
      <Dialog onClose={handleViewClose} open={viewOpen}>
        <AreYouSure
          Title={`Confirm User ${userStatus ? "Activation" : "Suspension"}`}
          para={`Are you sure you want to ${
            userStatus ? "activate" : "suspend"
          } this user?`}
          btntext={`${userStatus ? "Activate" : "Suspend"} User`}
          img={Are}
          cancel={handleViewClose}
          deletecake={() => changeFlow(userStatus)}
        />
      </Dialog>
      <Dialog onClose={AdminClose} open={adminOpen}>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "18px",
            padding: "30px",
            textAlign: "center",
          }}
        >
          <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
            <TextField
              sx={{ width: "100%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon sx={{ color: "black" }} />
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: "#FFFFFF",
                  borderRadius: "15px",
                  borderColor: "#EE548A",
                  margin: "0% 2%",
                  fontSize: "16px",
                },
              }}
              placeholder="User Name"
              variant="outlined"
              value={name}
              onChange={(e) => {
                const enteredName = e.target.value;
                setName(enteredName);
                setNameError(!/^[a-zA-Z].*$/.test(enteredName));
              }}
              error={nameError}
              helperText={nameError && "Name must start with a letter"}
            />
          </div>
          <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
            <TextField
              sx={{ width: "100%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon sx={{ color: "black" }} />
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: "#FFFFFF",
                  borderRadius: "15px",
                  borderColor: "#EE548A",
                  fontSize: "16px",
                  margin: "0% 4%",
                },
              }}
              placeholder="Email Address"
              variant="outlined"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError(!/\S+@\S+\.\S+/.test(e.target.value));
              }}
              error={emailError}
              helperText={emailError && "Please enter a valid email address"}
            />
          </div>
          <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
            <TextField
              sx={{ width: "100%" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon sx={{ color: "black" }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? (
                        <VisibilityIcon sx={{ color: "black" }} />
                      ) : (
                        <VisibilityOffIcon sx={{ color: "black" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: "#FFFFFF",
                  borderRadius: "15px",
                  borderColor: "#EE548A",
                  fontSize: "16px",
                  height: "57px",
                  margin: "0% 4%",
                },
              }}
              placeholder="Password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => {
                const enteredPassword = e.target.value;
                setPassword(enteredPassword);
                setPasswordError(
                  !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,12}$/.test(
                    enteredPassword
                  )
                );
              }}
              error={passwordError}
              helperText={passwordError && "Invalid password"}
            />
          </div>
          <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
            <FormControl sx={{ width: "100%" }}>
              <Select
                value={country ? country : "select"}
                onChange={handleCountryChange}
                sx={{
                  backgroundColor: "#FFFFFF",
                  borderRadius: "15px",
                  borderColor: "#EE548A",
                  fontSize: "16px",
                  margin: "0% 2%",
                  color: country ? "" : "#BCBCBC !important",
                  textAlign: "left",
                }}
              >
                <MenuItem value="select" disabled>
                  Please Select Country
                </MenuItem>
                {Object.keys(countries).map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <Button
            variant="contained"
            onClick={handleCreateUser}
            sx={{ maxWidth: "250px", textAlign: "center" }}
          >
            Create New Admin
          </Button>
        </Grid>
      </Dialog>
     
    </AdminLayout>
  );
}
