import { Card, Typography,  Button } from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Verify from "../assets/images/verify.svg";
import UnRegisterdLayout from "../components/layouts/UnRegisterdLayout";
import { useSearchParams } from "react-router-dom";
import axiosInstance from "../api/axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function VerifyAccount() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const token = searchParams.get("token");

  const activateAccount = () => {
    if (!token) {
      toast.error("Invalid Request");
      return;
    }
    axiosInstance
      .postJson("/api/user/activate", 
       {token} ,
      )
      .then((response) => {
        toast.success(response?.data?.data);
        return   navigate("/login");
      })
      .catch((error) => {
        console.error(error);
      });
  };
  return (
    <UnRegisterdLayout>
      <Container maxWidth="xl" sx={{ mt: "4vh", mb: "4vh" }}>
        <Card
          sx={{
            backgroundColor: "#ECEBEB",
            maxWidth: "538px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "18px",
            textAlign: "center",
            padding: "20px",
          }}
        >
          <Typography variant="h1" sx={{ mt: "5%", fontWeight: 400 }}>
            Verify Your Account
          </Typography>
          <img
            src={Verify}
            style={{ width: "100%", maxWidth: "300px" }}
            alt="login"
          />

          <Button  onClick={activateAccount} variant="contained" sx={{ maxWidth: "250px" }}>
            Verify{" "}
          </Button>
        </Card>
      </Container>
    </UnRegisterdLayout>
  );
}
