import React from "react";
import {
  Typography,
  Container,
  // List,
  // ListItem,
  // ListItemText,
} from "@mui/material";
import UnRegisterdLayout from "../components/layouts/UnRegisterdLayout";

export default function TermsAndConditions() {
  return (
    <UnRegisterdLayout>
      <Container maxWidth="md" style={{ textAlign: "justify" ,marginBottom: "8px" }}>
        <Typography variant="h4" mb={4} gutterBottom>
          Terms and Conditions
        </Typography>
        <Typography variant="body3" mb={2}>
          Welcome to The Home Cakes, a cake display web portal designed for
          commercial use. These terms and conditions govern your use of The Home
          Cakes and outline the rights and responsibilities between you (the
          user) and The Home Cakes. By using The Home Cakes, you agree to comply
          with these terms and conditions. If you disagree with any part of
          these terms and conditions, please do not use The Home Cakes.
        </Typography>
        <Typography variant="h3" mb={2} mt={2} gutterBottom>
          Use of Service
        </Typography>
      
        <ul>
          <li style={{ marginBottom: "8px" }}>
            {" "}
            <Typography variant="body3">
              The Home Cakes provides a platform for commercial entities,
              including bakeries, cake designers, and related businesses, to
              display and promote their cake products and services.
            </Typography>
          </li>

          <li style={{ marginBottom: "8px" }}>
            <Typography variant="body3">
              Users must register and create an account to access and utilise
              commercial features on The Home Cakes. By registering, users agree
              to provide accurate and complete information.
            </Typography>
          </li>
          <li style={{ marginBottom: "8px" }}>
            <Typography variant="body3">
              The Home Cakes reserves the right to modify or discontinue the
              service temporarily or permanently without notice.{" "}
            </Typography>
          </li>
        </ul>
        <Typography variant="h3" mb={2} mt={2} gutterBottom>
          User Responsibilities
        </Typography>
        <ul>
          <li style={{ marginBottom: "8px" }}>
            {" "}
            <Typography variant="body3">
              Users are responsible for the accuracy, legality, and quality of
              the content they upload, display, or share on The Home Cakes.{" "}
            </Typography>
          </li>

          <li style={{ marginBottom: "8px" }}>
            <Typography variant="body3">
              Users must comply with all relevant laws, regulations, and
              industry standards pertaining to the promotion and sale of cake
              products and services in the UK.{" "}
            </Typography>
          </li>
        </ul>
        <Typography variant="h3" mb={2} mt={2} gutterBottom>
          Commercial Content
        </Typography>
        <ul>
          <li style={{ marginBottom: "8px" }}>
            {" "}
            <Typography variant="body3">
              By uploading or sharing commercial content on The Home Cakes,
              users grant The Home Cakes a non-exclusive, transferable,
              sub-licensable, royalty-free, worldwide license to use, store,
              display, reproduce, modify, create derivative works, and
              distribute such content on and through The Home Cakes for
              promotional purposes.
            </Typography>
          </li>
          <li style={{ marginBottom: "8px" }}>
            <Typography variant="body3">
              Users retain ownership rights to their commercial content but
              grant The Home Cakes the necessary rights to display and promote
              it on the platform.{" "}
            </Typography>
          </li>
        </ul>
        <Typography variant="h3" mb={2} mt={2} gutterBottom>
          Sales and Transactions{" "}
        </Typography>
        <ul>
          <li style={{ marginBottom: "8px" }}>
            {" "}
            <Typography variant="body3">
              The Home Cakes may facilitate transactions between users and
              consumers interested in purchasing cake products or services.
              Users agree to abide by The Home Cakes' transactional policies and
              terms, including payment processing, shipping, and returns.
            </Typography>
          </li>
          <li style={{ marginBottom: "8px" }}>
            <Typography variant="body3">
              The Home Cakes is not a party to the transactions and does not
              assume liability for the quality, delivery, or legality of
              products or services sold through the platform.{" "}
            </Typography>
          </li>
        </ul>
        <Typography variant="h3" mb={2} mt={2} gutterBottom>
          Intellectual Property{" "}
        </Typography>
        <ul>
          <li style={{ marginBottom: "8px" }}>
            {" "}
            <Typography variant="body3">
              The Home Cakes respects intellectual property rights. Users must
              not upload or share content that infringes upon others'
              copyrights, trademarks, or intellectual property rights.
            </Typography>
          </li>
          <li style={{ marginBottom: "8px" }}>
            <Typography variant="body3">
              If users believe their intellectual property rights have been
              infringed upon, they should promptly contact The Home Cakes with
              relevant details for resolution.{" "}
            </Typography>
          </li>
        </ul>
        <Typography variant="h3" mb={2} mt={2} gutterBottom>
        Privacy Policy
        </Typography>
        <ul>
          <li style={{ marginBottom: "8px" }}>
            {" "}
            <Typography variant="body3">
            The Home Cakes respects user privacy. Please refer to our Privacy Policy for information on how we collect, use, and disclose personal and commercial information.
            </Typography>
          </li>
          
        </ul>
       
        <Typography variant="h3" mb={2} mt={2} gutterBottom>
        Limitation of Liability
        </Typography>
        <ul>
          <li style={{ marginBottom: "8px" }}>
            {" "}
            <Typography variant="body3">
            The Home Cakes is provided on an "as is" basis. The Home Cakes makes no warranties, expressed or implied, regarding the service's reliability, availability, or accuracy of commercial content.            </Typography>
          </li>
          <li style={{ marginBottom: "8px" }}>
            <Typography variant="body3">
            	The Home Cakes shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from the use or inability to use The Home Cakes for commercial purposes.
            </Typography>
          </li>
        </ul>
        <Typography variant="h3" mb={2} mt={2} gutterBottom>
        Indemnification
        </Typography>
        <ul>
          <li style={{ marginBottom: "8px" }}>
            {" "}
            <Typography variant="body3">
            Users agree to indemnify and hold harmless The Home Cakes, its affiliates, employees, and agents from any claims, damages, liabilities, or expenses arising from their commercial use of The Home Cakes or violation of these terms.     </Typography>
          </li>
      
        </ul>
        <Typography variant="h3" mb={2} mt={2} gutterBottom>
        Termination
        </Typography>
        <ul>
          <li style={{ marginBottom: "8px" }}>
            {" "}
            <Typography variant="body3">
            	The Home Cakes reserves the right to terminate or suspend a user's commercial access to the service without prior notice for violation of these terms and conditions.     </Typography>
          </li>
  
        </ul>
        <Typography variant="h3" mb={2} mt={2} gutterBottom>
        Changes to Terms and Conditions
        </Typography>
        <ul>
          <li style={{ marginBottom: "8px" }}>
            {" "}
            <Typography variant="body3">
          	The Home Cakes may revise these terms and conditions at any time. Users are responsible for regularly reviewing the terms. Continued use of The Home Cakes after modifications constitutes acceptance of the revised terms.     </Typography>
          </li>
         
        </ul>
        <Typography variant="h3" mb={2} mt={2} gutterBottom>
        Governing Law
        </Typography>
        <ul >
          <li style={{ marginBottom: "8px" }}>
            {" "}
            <Typography variant="body3">
            These terms and conditions shall be governed by and construed in accordance with the laws of England and Wales.    </Typography>
          </li>
          <li style={{ marginBottom: "8px" }}>
            {" "}
            <Typography variant="body3">
            	By using The Home Cakes for commercial purposes, you acknowledge that you have read, understood, and agreed to these terms and conditions.   </Typography>
          </li>
         
        </ul>

      </Container>
    </UnRegisterdLayout>
  );
}
