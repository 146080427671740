import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Divider, Grid } from "@mui/material";
import { AuthContext } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";


export default function SideNav({link1,text1,link2,text5,link5,text2,link3,text3,link4,text4,text6,link6}) {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  return (
    <Grid sx={{background: '#A14364', borderRadius: '14px',color:'white',padding:'25% 20%'}}>
     <Grid sx={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column',gap:'8px'}}>
    <Grid sx={{display:'flex',justifyContent:'center',alignItems:'flex-start',flexDirection:'column',gap:'20px',}}>
      <Link to={link1}>
        <Typography variant="body2">{text1}</Typography>
      </Link>
      <Link to={link2}>
        <Typography variant="body2">{text2}</Typography>
      </Link>
      {text3&&     <Link to={link3}>
        <Typography variant="body2">{text3}</Typography>
      </Link>}
      {text4&&   <Link to={link4}>
        <Typography variant="body2">{text4}</Typography>
      </Link>}
     {text5&& <Link to={link5}>
        <Typography variant="body2">{text5}</Typography>
      </Link>} 
      {text6&& <Link to={link6}>
        <Typography variant="body2">{text6}</Typography>
      </Link>} 
      <Divider/>
      </Grid>
      <Button variant="contained" onClick={handleLogout}> Logout </Button>
      </Grid>
    </Grid>
  );
}
