import React  from "react";
import { Button, Grid, Typography, TextField, Rating } from "@mui/material";
export default function WriteReview({
  reviewchange,
  review,
  write,
  cancel,
  ratechange,
  ratevalue,
  text
}) {
  return (
    <Grid
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        gap: "12px",
        padding: "18px",
      }}
    >
      <Typography variant="h3">Write A Review ? </Typography>
      <Rating
        sx={{ fontSize: "56px" }}
        value={ratevalue}
        onChange={ratechange}
      />
      <TextField
      multiline
      minRows={4}
        sx={{ width: "100%" }}
        InputProps={{
          sx: {
            backgroundColor: "#FFFFFF",
            borderRadius: "15px",
            borderColor: "#EE548A",

            fontSize: "16px",
          },
        }}
        placeholder="Write Your Review "
        variant="outlined"
        value={review}
        onChange={reviewchange}
      />
    

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          gap: "8px",
        }}
      >
        <Button variant="outlined" onClick={cancel}>
          {" "}
          Cancel
        </Button>
        <Button variant="contained" onClick={write}>
        {text}
        </Button>
      </div>
    </Grid>
  );
}
