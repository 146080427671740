import { Card, Typography, IconButton,TextField, Button, Grid } from "@mui/material";
import { Container } from "@mui/system";
import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../api/axios";
import CupCake from "../assets/images/cupcake.svg";
import EmailIcon from "@mui/icons-material/Email";
import InputAdornment from "@mui/material/InputAdornment";
import LockIcon from "@mui/icons-material/Lock";
import UnRegisterdLayout from "../components/layouts/UnRegisterdLayout";
import { toast } from "react-toastify";
import { AuthContext } from "../components/context/AuthContext";
import Dialog from "@mui/material/Dialog";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };



  const handleSubmit = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Please wait...");
    if (passwordError ) {
      toast.update(toastId, {
        render: "Password must be 8-12 characters long and include at least one capital letter, one number, and one special character",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
     
      return;
    }
    try {
      const response = await axiosInstance.post("/api/auth/login", {
        email,
        password,
      });

      if (response.status === 200) {
        toast.update(toastId, {
          render: "Login successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        const user = response.data;
       
     
        login(user);
        if (user?.data?.type === "SELLER_USER") {
          navigate("/dashboard");
       
        } else if (user?.data?.type === "BUYER_USER") {
          navigate("/dashboard");
        
        } else if (user?.data?.type === "SUPER_ADMIN") {
          navigate("/admin-dashboard");
        

        }
        else if (user?.data?.type === "MAINTAINER") {
          navigate("/admin-dashboard");
        
        } else {
          navigate("/");
        
        }
        
      } 
      if (response.status === 201) {
     
        toast.update(toastId, {
          render: response.data?.message,
          type: "warning",
          isLoading: false,
          autoClose: 3000,
        });
       
      }else {
        // Handle login error
      }
    } catch (error) {
      console.log('error', error)
      toast.update(toastId, {
        render: error?.response?.data,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
  const reset = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Please wait...");
    try {
      const response = await axiosInstance.post("/api/auth/forgotPassword", {
        email,
      });
      if (response.status === 200) {
      
        toast.update(toastId, {
          render: "Mail Sent successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        handleClose();
      }
      if (response.status === 201) {
      
        toast.update(toastId, {
          render: response.data?.message,
          type: "warning",
          isLoading: false,
          autoClose: 3000,
        });
       
      } else {
        // Handle login error
      }
    } catch (error) {
      toast.update(toastId, {
        render: error?.response?.data,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <UnRegisterdLayout>
      <Container maxWidth="xl" sx={{ mt: "4vh", mb: "4vh" }}>
        <Card
          sx={{
            backgroundColor: "#ECEBEB",
            maxWidth: "538px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "18px",
            textAlign: "center",
          }}
        >
          <Typography variant="h1" sx={{ mt: "5%", fontWeight: 400 }}>
            Sign in
          </Typography>
          <img loading="lazy" src={CupCake} alt="login" />

          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
              width: "100%",
              maxWidth: "800px",
            }}
          >
            <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
            <TextField
                sx={{ width: "100%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon sx={{ color: "black" }} />
                    </InputAdornment>
                  ),
                  sx: {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "15px",
                    borderColor: "#EE548A",
                    fontSize: "16px",
                    margin: "0% 4%",
                  },
                }}
                placeholder="Email Address"
                variant="outlined"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError(!/\S+@\S+\.\S+/.test(e.target.value));
                }}
                error={emailError}
                helperText={emailError && "Please enter a valid email address"}
              />
            </div>
            <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
            <TextField
                  sx={{ width: "100%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon sx={{ color: "black" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? (
                            <VisibilityIcon sx={{ color: "black" }} />
                          ) : (
                            <VisibilityOffIcon sx={{ color: "black" }} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    sx: {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "15px",
                      borderColor: "#EE548A",
                      fontSize: "16px",
                      height: "57px",
                      margin: "0% 4%",
                    },
                  }}
                  placeholder="Password"
                  variant="outlined"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => {
                    const enteredPassword = e.target.value;
                    setPassword(enteredPassword);
                    setPasswordError(
                      
                      !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,12}$/.test(
                        enteredPassword
                      )
                    );
                  }}
                  error={passwordError}
                  helperText={passwordError && "Invalid password"}
                />
            </div>
            <Typography
              onClick={handleClickOpen}
              variant="body3"
              sx={{ cursor: "pointer" }}
            >
              Forgot your password?
            </Typography>

            <Button
              type="submit"
              variant="contained"
              sx={{ maxWidth: "250px" }}
              onClick={handleSubmit}
              disabled={!email || !password}
            >
              Login
            </Button>
            <Typography variant="body3" sx={{ mb: "5%" }}>
              If you don't have an account, please <br />
              <b>
                {" "}
                <a href="/signup">Create an account</a>
              </b>
            </Typography>
          </Grid>
        </Card>
        <Dialog open={open} onClose={handleClose}>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "16px",
              padding: "16px",
            }}
          >
            <Typography variant="body3">
              Please Enter Your Mail Address For Reset Password..
            </Typography>

            <TextField
              sx={{ height: "57px", width: "100%", maxWidth: "440px" }}
              value={email}
              onChange={handleEmailChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon sx={{ color: "black" }} />
                  </InputAdornment>
                ),
                sx: {
                  backgroundColor: "#FFFFFF",
                  borderRadius: "15px",
                  borderColor: "#EE548A",
                  margin: "0% 4%",
                  fontSize: "16px",
                },
              }}
              placeholder="Email Address"
              variant="outlined"
              required
            />

            <Button
              type="submit"
              variant="contained"
              sx={{ maxWidth: "250px" }}
              onClick={reset}
            >
              Reset
            </Button>
          </Grid>
        </Dialog>
      </Container>
    </UnRegisterdLayout>
  );
}
