import React from 'react';
import { CircularProgress, Backdrop } from '@mui/material';
import { styled } from '@mui/system'; // Import styled from @mui/system

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1500, // Adjust the zIndex value
  color: '#EE548A',
  backgroundColor: 'rgba(0, 0, 0, 0.5)', // Make the backdrop darker
}));


const PageLoader = () => {
  return (
    <StyledBackdrop open>
      <CircularProgress style={{ width: '104px', height: '104px' }} color="inherit" />
    </StyledBackdrop>
  );
};

export default PageLoader;
