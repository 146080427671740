import AdminLayout from "../../components/layouts/AdminLayout";
import { Grid, Tab,Dialog, Tabs, Typography, Chip ,Avatar} from "@mui/material";
import axiosInstance from "../../api/axios";
import React, { useEffect, useState } from "react";
import AreYouSure from "../../components/cards/AreYouSure";
import Are from "../../assets/icons/you.svg";
import { toast } from "react-toastify";

export default function Suppliers() {

  const [value, setValue] = useState(0);
  const [Cakes, setCakes] = useState([]);
  const [viewOpen, setViewOpen] = useState(false);
  const [cakeId, setCakeId] = useState("");
  const [approved, setApproved] = useState(false);
  const [loggedInUserCountry, setLoggedInUserCountry] = useState("");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleViewOpen = (id, Status) => {

    setApproved(Status);
    setCakeId(id)
    setViewOpen(true);
  };
  const handleViewClose = () => {
    setViewOpen(false);
  };
    useEffect(() => {
    getCakes();
    getLoggedUser();
  }, [Cakes,loggedInUserCountry]);
  const getLoggedUser = async () => {
    try {
      axiosInstance
        .get("/api/user/current", {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          const user = response?.data?.data;
          setLoggedInUserCountry(user?.country);
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
      if (!err?.response) {
        toast.error("User not fount");
        return;
      }
    }
  };
    const getCakes = async () => {
    try {
    axiosInstance
        .post("/api/cake/getAll", {
          limit: 10000,
          page: 1,
        })
        .then((response) => {
          const Cakes = response?.data?.data?.items;
          if (loggedInUserCountry && loggedInUserCountry !== "ALL") {
        
            const filteredCakes = Cakes.filter(
              (cake) => cake?.country === loggedInUserCountry
            );
            setCakes(filteredCakes);
          } else if(loggedInUserCountry) {
          
            setCakes(Cakes || []);
          }
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
    }
  };
  const aproveCakes = async () => {
    const toastId = toast.loading("Please wait...");

    try {
      if (approved){
        axiosInstance
        .patch("/api/cake/approve", {
         id:cakeId,
        })
        .then((response) => {
          toast.update(toastId, {
            render: "Cake Approve successfully",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
          handleViewClose(); getCakes();
        })
        .catch((err) => {
          console.log("error", err);
          toast.update(toastId, {
            render: err?.response?.data,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          return err;
        });
      }else{
        axiosInstance
        .patch("/api/cake/reject", {
         id:cakeId,
        })
        .then((response) => {
          toast.update(toastId, {
            render: "Cake Reject successfully",
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
          handleViewClose(); getCakes();
        })
        .catch((err) => {
          console.log("error", err);
          toast.update(toastId, {
            render: err?.response?.data,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          return err;
        });
      }
     
    } catch (err) {
      console.log("error", err);
    }
  };

  return (
    <AdminLayout>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          padding: "8px",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{
            backgroundColor: "#F9FAFB",
            margin: "10px",
            width: "100%",
            maxWidth: "840px",
          }}
          variant="fullWidth"
        >
          <Tab label="Pending" />
          <Tab label="Approved" />
        </Tabs>
        {value === 1 && (
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
              width: "100%",
              marginTop: "20px",
              height: "500px",
              overflow: "auto",
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
                backgroundColor: "lightgray",
                color: "black",
                maxWidth: "840px",
                display: Cakes?.length > 0 ? "none" : "flex",
              }}
            >
              Sorry! No result found
            </Grid>
            {Cakes?.filter((cake) => cake.status === "APPROVED").map((Cake, index) => (
            <Grid
            key={index}
              sx={{
                display: "flex",
                flexDirection:{xs:'column',sm:'row'} ,
                justifyContent: "space-between",
                alignItems:'center',
                gap: "4px",
                marginTop: "2px",
                marginBottom: "2px",
                background: "#F3F4F6",
              
                width: "100%",
                maxWidth: "840px",
                padding: "12px",
                borderRadius:'45px'
              }}
            >
               <Avatar
                    src={Cake?.user?.image}
                    alt={Cake?.user?.user_name.charAt(0).toUpperCase()}
                    sx={{ width: 75, height: 75 }}
                  />
                      <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: { xs: "100px", sm: "400px" }["sm"],
                  whiteSpace: "nowrap",
                }}
                variant="body2"
              >
                user: {Cake?.user?.user_name}
              </Typography>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: { xs: "100px", sm: "400px" }["sm"],
                  whiteSpace: "nowrap",
                }}
                variant="body2"
              >
                {Cake?.name}
              </Typography>
              <Chip
                  label={Cake.status}
                  color="success"
                  variant="outlined"
                  //  onClick={() => handleOpen(quote._id)}
                />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{ display: { xs: "none", sm: "flex" }["sm"] }}
                  variant="quotebytext"
                >
              
                </Typography>
               
              </div>
            </Grid>
          ))}
          </Grid>
        )}
        {value === 0 && (
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",

              alignItems: "center",
              width: "100%",
              marginTop: "20px",
              height: "500px",
              overflow: "auto",
            }}
          >
            <Grid
              container
              sx={{
                justifyContent: "center",
                alignItems: "center",
                height: "200px",
                backgroundColor: "lightgray",
                color: "black",
                maxWidth: "840px",
                display: Cakes?.length > 0 ? "none" : "flex",
              }}
            >
              Sorry! No result found
            </Grid>
          {Cakes?.filter((cake) => cake.status ==="PENDING").map((Cake, index) => (
            <Grid
            key={index}
              sx={{
                display: "flex",
                flexDirection:{xs:'column',sm:'row'} ,
                justifyContent: "space-between",
                alignItems:'center',
                gap: "4px",
                marginTop: "2px",
                marginBottom: "2px",
                background: "#F3F4F6",
              
                width: "100%",
                maxWidth: "840px",
                padding: "12px",
                borderRadius:'45px'
              }}
            >
               <Avatar
                    src={Cake?.user?.image}
                    alt={Cake?.user?.user_name.charAt(0).toUpperCase()}
                    sx={{ width: 75, height: 75 }}
                  />
                           <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: { xs: "100px", sm: "400px" }["sm"],
                  whiteSpace: "nowrap",
                }}
                variant="body2"
              >
                user: {Cake?.user?.user_name}
              </Typography>
              <Typography
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  maxWidth: { xs: "100px", sm: "400px" }["sm"],
                  whiteSpace: "nowrap",
                }}
                variant="body2"
              >
                {Cake?.name}
              </Typography>
              <Chip
                  label={Cake.status}
                  color="warning"
                  variant="outlined"
                  //  onClick={() => handleOpen(quote._id)}
                />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  style={{ display: { xs: "none", sm: "flex" }["sm"] }}
                  variant="quotebytext"
                >
              
                </Typography>
                <Chip
                  label="Approve"
                  color="success"
                  sx={{borderRadius:'120px',padding:'10px'}}
                   onClick={() => handleViewOpen(Cake.id,true)}
                />
                    <Chip
                  label="Reject"
                  color="error"
                  sx={{borderRadius:'120px',padding:'10px'}}
                    onClick={() => handleViewOpen(Cake.id,false)}
                />
              </div>
            </Grid>
          ))}
          </Grid>
        )}
      </Grid>
      <Dialog onClose={handleViewClose} open={viewOpen}>
        <AreYouSure
          Title={`Confirm Cake  ${approved?'Approve':'Reject'} `}
          para={`  Are you sure you want to  ${approved?'Approvel':'Reject'} this Cake?`}
          btntext={`${approved?'Approve':'Reject'} Cake`}
          img={Are}
          cancel={handleViewClose}
          deletecake={() => aproveCakes()}
        />
      </Dialog>
    </AdminLayout>
  );
}
