import { Dialog, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import RegisterdLayout from "../../components/layouts/RegisterdLayout";
import ProductSmall from "../../components/cards/ProductSmall";
import axiosInstance from "../../api/axios";
import DeleteCard from "../../components/cards/DeleteCard";
import { toast } from "react-toastify";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
export default function MyCakes() {
 
  const [cakes, setCakes] = useState([]);
  const [viewOpen, setViewOpen] = useState(false);
  const [cakeId, setCakeId] = useState('');
  const storedUserString = localStorage.getItem("user");
  const storedUser = JSON.parse(storedUserString); 
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(3);
  const [totall, setTotall] = useState("");
  useEffect(() => {
    if (storedUser) {
      getCakes(storedUser?.id);
    }
  }, [storedUser]);
  const handleViewOpen = (id) => {
    setCakeId(id)
    setViewOpen(true);
  };
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };
  const handleViewClose = () => {
    setViewOpen(false);
  };
  const getCakes = async (id) => {
    try {
      axiosInstance
        .post("/api/cake/ByUserId", {
          id: id,
          limit: itemsPerPage,
          page: currentPage,
        })
        .then((response) => {
       
        
          const cakes = response?.data?.data?.items;
        
          setCakes(cakes || []);
          setTotall(response?.data?.data?.meta?.total);

        })
        .catch((err) => {
          console.log("error", err);
          return err;
        });
    } catch (err) {
      console.log("error", err);
    }
  };
  const deleteCake = async (id) => {
    const toastId = toast.loading("Please wait...");
    try {
      axiosInstance
        .post("/api/cake/delete", {
          id: cakeId,
        })
        .then((response) => {
          if(response?.data?.status==200){
            toast.update(toastId, {
              render: "Cake Delete successfully",
              type: "success",
              isLoading: false,
              autoClose: 3000,
            });
            getCakes(storedUser?.id)
            handleViewClose();
          }
         
        })
        .catch((err) => {
          toast.update(toastId, {
            render: err?.response?.data,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
    }
  };
  return (
    <RegisterdLayout>
      <Grid
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "start",
          width:'100%'
        }}
      >
        {cakes&&cakes?.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at)).map((product, index) => (
          <ProductSmall
            key={index}
            chip={product?.status}
            image={product?.images?.image_1}
            title={product?.name}
            para={product?.description}
            price={product?.price}
            cakeId={product?.id}
            deleteopen={()=>handleViewOpen(product?.id)}
          />
        ))}
      </Grid>
      <Stack spacing={2} sx={{margin:'0 auto', padding:'18px'}}>
          <Pagination
            count={Math.ceil(totall / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
          </Stack>
      <Dialog onClose={handleViewClose} open={viewOpen}>
        <DeleteCard
        cancel={handleViewClose}
        deletecake={deleteCake}
        />
      </Dialog>
    </RegisterdLayout>
  );
}
