import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import Footer from "../core/Footer";
import { NavBar } from "../core/NavBar";
import SideNav from "../core/SideNav";
import { Box, Typography } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import PageLoader from "./PageLoader";

const layoutStyles = {
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
};

const contentStyles = {
  flex: 1,
};

const AdminLayout = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const storedUserString = localStorage.getItem("user");
  const storedUser = JSON.parse(storedUserString); 

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);
  const link5 = storedUser?.type === "SUPER_ADMIN" ? "/admin-dashboard/admins" : null;
  const text5 = storedUser?.type === "SUPER_ADMIN" ? "Admins" : null;

  return (
    <div style={layoutStyles}>
      <NavBar userdash={true} />
      <Container maxWidth="xl">
        <Grid container marginTop="4%" marginBottom="4%">
          <Grid item xs={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <SideNav
              link1="/admin-dashboard"
              text1="Dashboard"
              link2="/admin-dashboard/users"
              text2="Users"
              link3="/admin-dashboard/cakes"
              text3="Cakes"
              link4="/admin-dashboard/requests"
              text4="Requests"
              link5={link5}
              text5={text5}
              link6="/admin-dashboard/profile"
              text6="My Profile"
            />
          </Grid>
          <Grid item xs={0.5} sx={{ display: { xs: 'none', sm: 'block' } }}></Grid>
          <Grid item xs={12} sm={9.5} style={contentStyles}>
          {loading && <PageLoader />}   {children}
          </Grid>
        </Grid>
      </Container>
      <Footer />
    </div>
  );
};

export default AdminLayout;
