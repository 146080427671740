import { Button, Card, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function ProductCard({ image, title, para,usd, price, cakeId }) {
  const navigate = useNavigate();
  const handleEditClick = () => {
    navigate(`/single-cake?id=${cakeId}`);
  };
  return (
    <Card sx={{ maxWidth: "419px", margin: "10px" }}>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: "10px",
          padding: "10px",
        }}
      >
        <div style={{width:'100%', maxWwidth: "396px", height: "234px", overflow: "hidden" }}>
          <img
            src={image}
            id={cakeId}
            alt="product"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              borderRadius: "45px 45px 0px 0px",
            }}
          />
        </div>
        <Grid
          sx={{
            mb: "20px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              whiteSpace: "nowrap", 
              overflow: "hidden", 
              textOverflow: "ellipsis",
              maxWidth:'380px'
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body3"
            style={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitLineClamp: 2, // Limit the text to three lines
              WebkitBoxOrient: "vertical",
              height: "64px",
            }}
          >
            {para}
          </Typography>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "4px",
            padding: "4px",
          }}
        >
          <Button variant="outlined"> Price: {price} {usd}</Button>
          <Button variant="contained" onClick={handleEditClick}>
            {" "}
            View
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
