import React from "react";
import RegisterdLayout from '../../components/layouts/RegisterdLayout'
import { Button, Grid, Typography } from "@mui/material";
import Cat from "../../assets/images/welcome.svg"
import { Link } from "react-router-dom";

export default function Dashboard() {


  return (
    <RegisterdLayout>
        <Grid container  sx={{
           display: "flex",
           alignItems: "center",
           justifyContent: "center",
           flexDirection: "column",
           gap: "8px",
           textAlign:{ xs: 'center', sm: '' }
        }}>
          
       <img loading="lazy" src={Cat} style={{width:'100%'}} alt="welcome"/>
       <Typography variant="h2"> Welcome to the Home Cakes Dashboard </Typography>
       <Typography variant="body2"> Here you can manage your cake orders and settings. </Typography>
       <Link to="/dashboard/add-cake">   <Button variant="contained"  sx={{maxWidth:'200px',}} >Add Cake </Button></Link>
       </Grid>
        </RegisterdLayout>
  )
}
