import React, { useState } from "react";
import UnRegisterdLayout from "../components/layouts/UnRegisterdLayout";
import { Typography, TextField, Grid, Button } from "@mui/material";
import { Container } from "@mui/system";
import axios from "axios";
import { toast } from "react-toastify";
import Sucss from "../assets/images/sucss.svg";

export default function Contact() {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [msg, setMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState(false);

  const handleSubmit = async (event) => {
    const toastId = toast.loading("Please wait...");
    const phoneRegex = /^(\+\d{1,3})?\d{10}$/;
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (!phoneRegex.test(phone)) {
      toast.update(toastId, {
        render: "Please enter a valid phone number.",
        type: "warning",
        isLoading: false,
        autoClose: 3000,
      });

      return;
    }
    if (!emailRegex.test(email)) {
      toast.update(toastId, {
        render: "Please enter a valid Email.",
        type: "warning",
        isLoading: false,
        autoClose: 3000,
      });

      return;
    }

    try {
      const response = await axios.post("/api/support/contactUs", {
        name: name,
        phone: phone,
        email: email,
        message: msg,
      });

      if (response.status === 200) {
        toast.update(toastId, {
          render: "Mail Sent successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
         setSuccessMsg(true);
      }
      if (response.status === 201) {
        toast.update(toastId, {
          render: response.data?.message,
          type: "warning",
          isLoading: false,
          autoClose: 3000,
        });
         setSuccessMsg(false);
      } else {
      }
    } catch (error) {
      console.log("error", error);
      toast.update(toastId, {
        render: error?.response?.data,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
       setSuccessMsg(false);
    }
  };
  return (
    <UnRegisterdLayout>
      <Container maxWidth="xl" sx={{ mb: "4vh", padding: "2px" }}>
      {successMsg ? (
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "18px",
              textAlign: "center",
            }}
          >
            <Typography variant="body1">
            Thank you for choosing TheHomeCakes.com<br></br>
              
            </Typography>
            <img
              src={Sucss}
              alt="Sucss"
              style={{ width: "100%", maxWidth: "500px" }}
            />
          </Grid>
        ) : (
        <Grid
          className="contactomg"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "2%",
            height: "700px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "14px",
              padding: "30px",
              margin: { xs: "0px", sm: "40px" },
            }}
            className="contactcard"
          >
            <Typography
              variant="h1"
              sx={{ paddingBottom: "4%", paddingTop: "4%" }}
            >
              {" "}
              Contact
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                maxWidth: "540px",
              }}
            >
              <TextField
                sx={{ height: "57px", width: "100%" }}
                value={name}
                onChange={(e) => setName(e.target.value)}
                InputProps={{
                  sx: {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "15px",
                    borderColor: "#EE548A",
                    fontSize: "16px",
                  },
                }}
                placeholder=" Name "
                variant="outlined"
                required
              />
              <TextField
                sx={{ height: "57px", width: "100%" }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                InputProps={{
                  sx: {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "15px",
                    borderColor: "#EE548A",
                    margin: "0% 4%",
                    fontSize: "16px",
                  },
                }}
                placeholder="Email"
                variant="outlined"
                required
              />
            </div>
            <TextField
              sx={{ height: "57px", width: "100%", maxWidth: "540px" }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              InputProps={{
                sx: {
                  backgroundColor: "#FFFFFF",
                  borderRadius: "15px",
                  borderColor: "#EE548A",

                  fontSize: "16px",
                },
              }}
              placeholder="Phone"
              variant="outlined"
              required
            />
            <TextField
              sx={{ width: "100%", maxWidth: "540px" }}
              value={msg}
              onChange={(e) => setMsg(e.target.value)}
              InputProps={{
                sx: {
                  backgroundColor: "#FFFFFF",
                  borderRadius: "15px",
                  borderColor: "#EE548A",
                  fontSize: "16px",
                },
              }}
              placeholder="message"
              variant="outlined"
              required
              rows={6}
              multiline
            />
            <Button
              variant="contained"
              sx={{ maxWidth: "300px" }}
              onClick={handleSubmit}
              disabled={!name || !email || !phone || !msg}
            >
              {" "}
              Submit
            </Button>
          </div>
        </Grid>
         )}
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: "2%",
          }}
        >
          <Typography variant="h1" mb={8}> Contact Us
</Typography>

          <Typography variant="body1" textAlign={'center'}>
          Thank you for choosing TheHomeCakes.com as your go-to platform for all things sweet and delightful! Your satisfaction is at the heart of our mission, and we're here to ensure your experience is as enjoyable as indulging in a slice of your favorite cake.
</Typography>
          <br></br>
          <Typography variant="body1" textAlign={'center'}>
          Our customer support team is here to assist you with any inquiries, orders, or assistance you may need. Whether you have questions about a specific cake, need help with placing an order, or just want to share your thoughts, we're ready to help.       </Typography>
          <br></br>
          <br></br>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d158858.58518529957!2d-0.2664028309972148!3d51.52852620421266!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2slk!4v1690445096930!5m2!1sen!2slk"
            width="1200"
            height="450"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </Grid>
      </Container>
    </UnRegisterdLayout>
  );
}
