import React, { useEffect, useState } from "react";
import AdminLayout from "../../components/layouts/AdminLayout";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import axiosInstance from "../../api/axios";
import {
  Dialog,
  Avatar,
} from "@mui/material";
import Box from "@mui/material/Box";
import AreYouSure from "../../components/cards/AreYouSure";
import Are from "../../assets/icons/you.svg";
import { toast } from "react-toastify";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [viewOpen, setViewOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [userStatus, setUserStatus] = useState("");
  const [loggedInUserCountry, setLoggedInUserCountry] = useState("");

  const handleViewOpen = (id, userStatus) => {
    setUserStatus(userStatus);
    setUserId(id);
    setViewOpen(true);
  };
  const handleViewClose = () => {
    setViewOpen(false);
  };
  useEffect(() => {
    getLoggedUser();
    getUsers();
  }, [loggedInUserCountry, users]);
  function toDate(str) {
    let test = new Date(str.replace("T", " ").slice(0, -1));

    return test;
  }
  const getLoggedUser = async () => {
    try {
      axiosInstance
        .get("/api/user/current", {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
  
          const user = response?.data?.data;
          setLoggedInUserCountry(user?.country);
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
      if (!err?.response) {
        toast.error("User not fount");
        return;
      }
    }
  };
  const getUsers = async () => {
    try {
      axiosInstance
        .get("/api/user/getAll")
        .then((response) => {
          const allUsers = response?.data?.data || [];
          // if (loggedInUserCountry && loggedInUserCountry !== "ALL") {
          //   // Filter users based on the logged-in user's country
          //   const filteredUsers = allUsers.filter(
          //     (user) => user?.country === loggedInUserCountry
          //   );
            setUsers(allUsers);
          // } else if(loggedInUserCountry) {
          //   // Show all users
          //   setUsers(allUsers || []);
          // }
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
    }
  };
  const changeFlow = async (disabled) => {
    const toastId = toast.loading("Please wait...");

    try {
      const response = await axiosInstance.patch(
        disabled ? "/api/user/enable" : "/api/user/disable",
        {
          id: userId,
        }
      );
      if (response.status === 200) {
        toast.update(toastId, {
          render: "User enable/disable successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        handleViewClose();
        getUsers();
      }
    } catch (err) {
      console.log("error", err);
      toast.update(toastId, {
        render: err?.response?.data,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
      return err;
    }
  };
  const userColumns = [
    { field: "oder", headerName: "ID", width: 50 },
    {
      field: "image",
      headerName: "Profile",
      width: 50,
      editable: false,
      renderCell: (params) => {
        return (
          <Avatar
            key={params?.row.id}
            alt={params?.row.name.charAt(0)}
            src={params?.row.image}
            style={{ width: 35, height: 35, textTransform: "capitalize" }}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 100,
      editable: false,
    },
    {
      field: "country",
      headerName: "Country",
      width: 100,
      editable: false,
    },

    {
      field: "email",
      headerName: "Email",
      width: 200,
      editable: false,
    },
    {
      field: "phone_number",
      headerName: "Phone Number",
      type: "number",
      width: 200,
      editable: false,
    },

    {
      field: "is_active",
      headerName: "Active",
      type: "boolean",
      width: 80,
      editable: false,
    },
    {
      field: "created_at",
      headerName: "Created Date & Time",
      type: "dateTime",

      width: 200,
      editable: false,
    },
    {
      field: "user_type",
      headerName: "Type",

      width: 80,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {params?.row?.is_disabled ? (
              <Chip
                label="Activate"
                variant="filled"
                onClick={() =>
                  handleViewOpen(params?.row?.id, params?.row?.is_disabled)
                }
                color="success"
              />
            ) : (
              <Chip
                label="Suspend"
                variant="filled"
                onClick={() =>
                  handleViewOpen(params?.row?.id, params?.row?.is_disabled)
                }
                color="error"
              />
            )}
          </Box>
        );
      },
    },
  ];
  const userRows = users?.filter((user) => loggedInUserCountry === "ALL" || user?.country === loggedInUserCountry).map((User, index) => ({
    id: User.id,
    oder: index + 1,
    image: User.image,
    name: User.user_name,
    email: User.email,
    phone_number: User.phone_number,
    post_code: User.post_code,
    region: User.region,
    is_active: User.is_active,
    country: User.country,
    user_type: User.user_type,
    is_disabled: User.is_disabled,
    created_at: toDate(User.created_at),
  }));
  return (
    <AdminLayout>
      <DataGrid
        rows={userRows}
        style={{ fontSize: "14px" }}
        columns={userColumns}
        pageSize={[10]}
        rowsPerPageOptions={[10]}
        slots={{ toolbar: GridToolbar }}
        experimentalFeatures={{ newEditingApi: true }}
      />
      <Dialog onClose={handleViewClose} open={viewOpen}>
        <AreYouSure
        Title={`Confirm User ${userStatus ? 'Activation' : 'Suspension'}`}
        para={`Are you sure you want to ${userStatus ? 'activate' : 'suspend'} this user?`}
        btntext={`${userStatus ? 'Activate' : 'Suspend'} User`}
          img={Are}
          cancel={handleViewClose}
          deletecake={() => changeFlow(userStatus)}
          
        />
      </Dialog>
    </AdminLayout>
  );
}
