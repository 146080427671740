import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Grid,
  TextField,
  InputAdornment,
  Typography,
  Slider,
  Chip,
} from "@mui/material";
import User from "../../assets/images/upload.svg";
import EmailIcon from "@mui/icons-material/Email";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// import PhoneIcon from "@mui/icons-material/Phone";
import RegisterdLayout from "../../components/layouts/RegisterdLayout";
import axiosInstance from "../../api/axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import AvatarEditor from "react-avatar-editor";
// import { GetBloB } from "../../api/azure";
import {
  CountrySelector,
  defaultCountries,
  parseCountry,
} from "react-international-phone";
import "react-international-phone/style.css";

export default function Profile() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [flag, setFlag] = useState("gb");
  const [nameError, setNameError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const inputRef = useRef();
  const editorRef = useRef();
  const [selectedImage, setSelectedImage] = useState(null);
  const [profileImage, setProfileImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCrop, setShowCrop] = useState(true);
  const [zoomValue, setZoomValue] = useState(1);
  const [imageError, setImageError] = useState(false);
  const countryCode = defaultCountries.filter((country) => {
    const { iso2 } = parseCountry(country);
    return ["ca", "lk", "gb"].includes(iso2);
  });
  const getLoggedUser = async () => {

    try {
      axiosInstance
        .get("/api/user/current", {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        })
        .then((response) => {
          const user = response?.data?.data;
          setName(user?.user_name);
          setEmail(user?.email);
          setPhone(user?.phone_number);
          setShowCrop(user?.image ? false : true);
          setCroppedImage(user?.image);
          setSelectedImage(user?.image);
          const phonePrefix = user?.phone_number?.substring(0, 3);
          if (phonePrefix === "+44") {
            setFlag("gb");
          } else if (phonePrefix === "+1") {
            setFlag("ca");
          } else if (phonePrefix === "+94") {
            setFlag("lk");
          }
        })
        .catch((err) => {
          console.log("error", err);

          return err;
        });
    } catch (err) {
      console.log("error", err);
      if (!err?.response) {
        toast.error("User not fount");
        return;
      }
    }
  };
  useEffect(() => {
    getLoggedUser();
  }, []);
  const [filename, setFileName] = useState("");
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    // console.log("file", file);
    if (file) {
      setFileName(file.name);
      setSelectedImage(URL.createObjectURL(file));
    }
  };
  const handleSave = (event) => {
    // console.log("croppedImage", croppedImage);
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      // Check file size
      const fileSizeInMB = file.size / (1024 * 1024);
      if (fileSizeInMB > 3) {
        toast.error("File size exceeds 3MB");
        return;
      }
      // Check file type
      if (!file.type.startsWith("image/")) {
        toast.error("File is not an image");
        return;
      }
      setProfileImage(file);
    }

    if (editorRef.current) {
      const canvas = editorRef.current.getImage().toDataURL();
      setCroppedImage(canvas);
      setProfileImage(base64ToHtmlFile(canvas, filename));

      setShowCrop(false); // Hide the crop area after saving
    }
  };

  function base64ToHtmlFile(base64String, fileName) {
    const mimeType = base64String.split(",")[0].match(/:(.*?);/)[1];
    const byteCharacters = atob(base64String.split(",")[1]);
    const byteArrays = [];

    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays.push(byteCharacters.charCodeAt(i));
    }

    const byteArray = new Uint8Array(byteArrays);
    const file = new File([byteArray], fileName, { type: mimeType });

    return file;
  }

  const handleClick = () => {
    inputRef.current.click();
  };
  const handleRemove = () => {
   
    setSelectedImage(null);
    setCroppedImage(null);
    setShowCrop(true);
  };
  const handleZoomChange = (event, newValue) => {
    setZoomValue(newValue);
  };
  const update = async (event) => {
    event.preventDefault();
 
    const toastId = toast.loading("Please wait...");
    const data = {
      user_name: name,
      phone_number: phone,
    };
    const phoneRegex = /^(\+\d{1,3})?\d{10}$/;

    if (!phoneRegex.test(phone)) {
      toast.update(toastId, {
        render: "Please enter a valid phone number.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      }); 
      return;
    }
    try {
      const response = await axiosInstance.patch("/api/user/edit", data);

      if (response.status === 200) {
        toast.update(toastId, {
          render: "User Details update successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.log("error", error);
      toast.update(toastId, {
        render: error?.response?.data,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };
 
  const uploadImage = async (event) => {
    event.preventDefault();
    const toastId = toast.loading("Please wait...");

    if (!croppedImage) {
      setImageError(true);
      return;
    }

    // Remove the 'data:image/jpeg;base64,' prefix from the base64 image data
    const base64Data = croppedImage.split(",")[1]; // Split at the comma and take the second part

    const data = {
      image: base64Data,
    };

    try {
      const response = await axiosInstance.patch("/api/user/edit", data);

      if (response.status === 200) {
        toast.update(toastId, {
          render: "User Details updated successfully",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        setSelectedImage(null);
        setCroppedImage(null);
        getLoggedUser();
      
      }
    } catch (error) {
      console.log("error", error);
      toast.update(toastId, {
        render: error?.response?.data,
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  return (
    <RegisterdLayout>
      <div className="userback">
        <Grid container padding="4%">
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "18px",
              marginBottom: "10px",
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              ref={inputRef}
              style={{ display: "none" }}
            />
            {!selectedImage && (
              <div
                onClick={handleClick}
                className="flex flex-row justify-center items-center gap-2 p-4"
              >
                <img
                  src={User}
                  alt="User"
                  style={{ width: "100%", maxWidth: "400px" }}
                />
                <Typography sx={{ color: imageError ? "red" : "" }}>
                  {imageError
                    ? "Please Upload an Image"
                    : "Drag and drop image  or click to browse"}
                </Typography>
              </div>
            )}

            {selectedImage && showCrop && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <AvatarEditor
                  ref={editorRef}
                  image={selectedImage}
                  width={200}
                  height={200}
                  border={50}
                  borderRadius={150}
                  rotate={0}
                  scale={zoomValue}
                />
                <Slider
                  value={zoomValue}
                  onChange={handleZoomChange}
                  min={1}
                  max={10}
                  step={0.1}
                />
                <Button
                  sx={{ height: "34px !important ", marginTop: "10px" }}
                  variant="outlined"
                  onClick={handleSave}
                >
                  Save
                </Button>
              </div>
            )}

            {croppedImage && !showCrop && (
              <>
                <div style={{ justifyContent: "flex-start" }}>
                  <img
                    src={croppedImage}
                    alt="Cropped"
                    style={{ width: "100%", maxWidth: "150px" }}
                  />
                  {/* <button onClick={handleUpload}>Upload</button> */}
                </div>
                <div style={{ justifyContent: "flex-end" }}>
                  <Chip
                    label="Remove"
                    variant="outlined"
                    color="error"
                    onClick={handleRemove}
                  />
                </div>
              </>
            )}

            <Button
              onClick={uploadImage}
              variant="contained"
              sx={{ maxWidth: "200px" }}
              disabled={!croppedImage && showCrop}
            >
              {" "}
              Upload
            </Button>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Grid
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                justifyContent: "center",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <div style={{ height: "57px", width: "100%", maxWidth: "220px" }}>
                <TextField
                  sx={{ width: "100%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircleIcon sx={{ color: "black" }} />
                      </InputAdornment>
                    ),
                    sx: {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "15px",
                      borderColor: "#EE548A",

                      fontSize: "16px",
                    },
                  }}
                  placeholder="User Name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => {
                    const enteredName = e.target.value;
                    setName(enteredName);
                    setNameError(!/^[a-zA-Z].*$/.test(enteredName));
                  }}
                  error={nameError}
                  helperText={nameError && "Name must start with a letter"}
                />
              </div>

              <div style={{ height: "57px", width: "100%", maxWidth: "220px" }}>
                <TextField
                  sx={{ width: "100%" }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <CountrySelector
                          selectedCountry={flag}
                          countries={countryCode}
                          onSelect={({ iso2, dialCode }) => {
                            setPhone(`+${dialCode}`);
                            setFlag(iso2);
                          }}
                        />
                      </InputAdornment>
                    ),
                    sx: {
                      backgroundColor: "#FFFFFF",
                      borderRadius: "15px",
                      borderColor: "#EE548A",
                      fontSize: "16px",
                    },
                  }}
                  placeholder="Phone Number"
                  variant="outlined"
                  value={phone}
                  onChange={(e) => {
                    setPhone(e.target.value);
                    setPhoneError(!/^\+[\d\s-]+$/.test(e.target.value));
                  }}
                  error={phoneError}
                  helperText={phoneError && "Please enter a valid phone number"}
                />
              </div>
            </Grid>
            <div style={{ height: "57px", width: "100%", maxWidth: "440px" }}>
              <TextField
                sx={{ width: "100%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon sx={{ color: "black" }} />
                    </InputAdornment>
                  ),
                  sx: {
                    backgroundColor: "#FFFFFF",
                    borderRadius: "15px",
                    borderColor: "#EE548A",
                    fontSize: "16px",
                    width: "100%",
                    maxWidth: "440px",
                    height: "57px",
                  },
                }}
                placeholder="Email Address"
                variant="outlined"
                value={email}
              />
            </div>

            <Button
              variant="contained"
              sx={{ maxWidth: "200px" }}
              onClick={update}
              disabled={!name || !phone || phoneError || nameError}
            >
              {" "}
              Update
            </Button>
            <Link to="/dashboard/changePass">
              <Typography>Change your password ? </Typography>
            </Link>
          </Grid>
        </Grid>
      </div>
    </RegisterdLayout>
  );
}
